import React, { useState } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
  useParams,
} from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/new-age.scss';

import Home from './pages/Home';
import Room from './pages/Room';
import NewHome from './pages/NewHome';
import Info from './pages/Info';

function App() {
  const [survey, setSurvey] = useState(false);

  const NewHomePage = () => {
    const history = useHistory();
    return <NewHome survey={survey} goRoom={(roomId) => history.push(`/room/${roomId}`)} />;
  };

  const HomePage = () => {
    const history = useHistory();
    return <Home survey={survey} goRoom={(roomId) => history.push(`/room/${roomId}`)} />;
  };

  const InfoPage = () => {
    const history = useHistory();
    return <Info survey={survey} goRoom={(roomId) => history.push(`/room/${roomId}`)} />;
  };

  const RoomPage = () => {
    const { roomId } = useParams();
    const history = useHistory();
    return (
      <Room
        roomId={roomId}
        goHome={(openSurvey) => {
          history.push('/');
          setSurvey(openSurvey);
        }}
      />
    );
  };

  return (
    <Router>
      <Switch>
        <Route exact path="/home" component={HomePage} />
        <Route exact path="/security" component={InfoPage} />
        <Route exact path="/room/:roomId" component={RoomPage} />
        <Route exact path="/" component={NewHomePage} />
      </Switch>
    </Router>
  );
}

export default App;
