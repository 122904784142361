import { db, firestore } from '../services/firebase';
import Peer from './peer';
import Caller from './call';
import {
  VIDEO_BITRATE,
  setMediaBitrates,
  getMediaBitrate,
  initHark,
} from './globals';

export default class FirebaseCaller extends Caller {
  async joinRoomById(roomId, name) {
    this.cleanUp();
    this.name = name;
    localStorage.name = name;
    console.log('name', name);
    console.log(roomId);
    const roomRef = firestore.collection('rooms').doc(`${roomId}`);
    this.membersCollection = roomRef.collection('members');
    const user = { name };
    let userRef;
    if (this.userId) {
      userRef = this.membersCollection.doc(this.userId);
      userRef.set(user);
    } else {
      userRef = await this.membersCollection.add(user);
      this.userId = userRef.id;
    }

    const userStatusDatabaseRef = db.ref(`/status/${roomId}/${this.userId}`);
    this.userStatusDatabaseRef = userStatusDatabaseRef;
    userStatusDatabaseRef.onDisconnect().remove().then(() => {
      userStatusDatabaseRef.set({ state: 'online' }); // change to just one boolean?
    });
    const connectedRef = db.ref('.info/connected');
    const self = this;
    let retry = false;
    connectedRef.on('value', (snap) => {
      console.log('online', snap.val());
      if (snap.val() === false) {
        retry = true;
      } else if (retry) {
        connectedRef.off('value'); // detatch this listener
        self.joinRoomById(roomId, name);
      }
    });

    this.unsubscribeDb = this.membersCollection.onSnapshot((snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        const themId = change.doc.id;
        if (change.type === 'added' && themId !== this.userId) {
          await this.handleNewPeer(themId, change.doc.data().name);
          this.peers[themId].connected = true;
        }
        if (change.type === 'removed' && themId !== this.userId) {
          if (this.peers[themId]) {
            this.peers[themId].connected = false;
          }
          this.callback();
          console.log(this);
          // this.handleDisconnect(themId)
        }
      });
    });
    this.roomId = roomId;
    this.state = 'call';
    this.callback();
  }

  async handleNewPeer(themId, name) {
    if (this.peers[themId]) {
      return;
    }
    this.peers[themId] = new Peer(themId, name, this.stream, this.callback);
    const peer = this.peers[themId];
    peer.onDisconnect = () => {
      this.handleDisconnect(themId);
      this.callback();
    };

    this.stream.getTracks().forEach((track) => {
      if (track.kind === 'video') {
        peer.videoSender = peer.peerConnection.addTrack(track, this.stream);
      }
      if (track.kind === 'audio') {
        peer.audioSender = peer.peerConnection.addTrack(track, this.stream);
        initHark(this);
      }
    });
    peer.peerConnection.addEventListener('track', (event) => {
      console.log('Got remote track:', event.streams[0]);
      event.streams[0].getTracks().forEach((track) => {
        console.log('Add a track to the stream:', track);
        peer.stream.addTrack(track);
        if (track.kind === 'audio') {
          initHark(peer);
        }
      });
    });
    const self = this;
    const setChannel = (channel) => {
      if (channel.label === 'status') {
        peer.statusChannel = channel;
        channel.addEventListener('open', (event) => {
          console.log('data channel opened');
          if (self.audioMuted) {
            event.target.send('audiomute');
          }
          if (self.videoMuted) {
            event.target.send('videomute');
          }
          if (self.shareScreen) {
            event.target.send('startsharescreen');
          }
          if (!peer.receiveVideo) {
            event.target.send('stopvideo');
          }
        });
        channel.addEventListener('close', () => {
          console.log('data channel closed');
        });
        channel.addEventListener('message', peer.handleStatusMessage());
      } else if (channel.label === 'file') {
        peer.fileChannel = channel;
        channel.addEventListener('message', peer.handleFileMessage());
      } else if (channel.label === 'chat') {
        peer.chatChannel = channel;
        channel.addEventListener('message', peer.handleChatMessage());
      }
    };
    if (themId > this.userId) { // use [me][them] (I am caller) old create
      peer.connectionRef = this.membersCollection.doc(this.userId).collection('connections').doc(themId);
      setChannel(peer.peerConnection.createDataChannel('status'));
      setChannel(peer.peerConnection.createDataChannel('file'));
      setChannel(peer.peerConnection.createDataChannel('chat'));
      peer.fileChannel.binaryType = 'arraybuffer';

      // Code for collecting ICE candidates below
      peer.peerConnection.addEventListener('icecandidate', FirebaseCaller.onGenerateCallerIceCandidate(peer));

      // Code for creating a room below
      const offer = await peer.peerConnection.createOffer();
      await peer.peerConnection.setLocalDescription(offer);
      offer.sdp = setMediaBitrates(offer.sdp);
      console.log('Created offer:', offer);

      const offerJson = {
        offer: {
          type: offer.type,
          sdp: offer.sdp,
        },
      };

      await peer.connectionRef.set(offerJson);

      // Listening for remote session description below
      peer.unsubscribeDbPeer = peer.connectionRef.onSnapshot(async (snapshot) => {
        const data = snapshot.data();
        if (!peer.peerConnection.currentRemoteDescription && data && data.answer) {
          const { answer } = data;
          console.log('Got remote description: ', answer);
          peer.maxVideoBitrate = getMediaBitrate(answer.sdp, 'video');
          if (peer.maxVideoBitrate > VIDEO_BITRATE()) {
            answer.sdp = setMediaBitrates(answer.sdp);
          }

          const rtcSessionDescription = new RTCSessionDescription(answer);
          await peer.peerConnection.setRemoteDescription(rtcSessionDescription);

          // Listen for remote ICE candidates below
          peer.unsubscribeDbIce = peer.connectionRef.collection('calleeCandidates').onSnapshot((snapshotIce) => {
            snapshotIce.docChanges().forEach(async (change) => {
              if (change.type === 'added') {
                const dataIce = change.doc.data();
                await peer.peerConnection.addIceCandidate(new RTCIceCandidate(dataIce));
              }
            });
          });
        }
      });
    }
    if (themId < this.userId) { // use [them][me] (I am callee) old join
      // need to receive offer
      peer.connectionRef = this.membersCollection.doc(themId).collection('connections').doc(this.userId);
      peer.peerConnection.addEventListener('datachannel', (event) => {
        setChannel(event.channel);
      });

      // Code for collecting ICE candidates below
      peer.peerConnection.addEventListener('icecandidate', FirebaseCaller.onGenerateCalleeIceCandidate(peer));

      // Code for creating SDP answer below
      peer.unsubscribeDbPeer = peer.connectionRef.onSnapshot(async (snapshot) => {
        const data = snapshot.data();
        if (peer.peerConnection.currentRemoteDescription || !data || !data.offer) {
          console.log('NOPE');
          return;
        }

        const { offer } = data;
        console.log('Got offer:', offer, ' from:', themId);
        // EXPERIMENT

        peer.maxVideoBitrate = getMediaBitrate(offer.sdp, 'video');
        if (peer.maxVideoBitrate > VIDEO_BITRATE()) {
          offer.sdp = setMediaBitrates(offer.sdp);
        }

        await peer.peerConnection.setRemoteDescription(new RTCSessionDescription(offer));

        const answer = await peer.peerConnection.createAnswer();
        console.log('Created answer:', answer, ' for:', themId);
        await peer.peerConnection.setLocalDescription(answer);
        answer.sdp = setMediaBitrates(answer.sdp);

        const roomWithAnswer = {
          answer: {
            type: answer.type,
            sdp: answer.sdp,
          },
        };
        await peer.connectionRef.update(roomWithAnswer);

        // Listening for remote ICE candidates below
        peer.unsubscribeDbIce = peer.connectionRef.collection('callerCandidates').onSnapshot((snapshotIce) => {
          snapshotIce.docChanges().forEach(async (change) => {
            if (change.type === 'added') {
              const dataIce = change.doc.data();
              await peer.peerConnection.addIceCandidate(new RTCIceCandidate(dataIce));
            }
          });
        });
      });
    }
    peer.setStatusListeners(this.handleDisconnect);
  }

  static onGenerateCallerIceCandidate(peer) {
    return (event) => {
      if (!event.candidate) {
        console.log('Got final candidate!');
        return;
      }
      peer.connectionRef.collection('callerCandidates').add(event.candidate.toJSON());
    };
  }

  static onGenerateCalleeIceCandidate(peer) {
    return (event) => {
      if (!event.candidate) {
        console.log('Got final candidate!');
        return;
      }
      peer.connectionRef.collection('calleeCandidates').add(event.candidate.toJSON());
    };
  }

  handleDisconnect(id) {
    const peer = this.peers[id];
    // peer already disconnected
    if (!peer) {
      return;
    }
    console.log('Disconnected from ', id);

    if (peer.statusChannel && peer.statusChannel.readyState === 'open') {
      peer.statusChannel.send('disconnect');
    }
    peer.stream.getTracks().forEach((track) => track.stop());

    if (typeof peer.unsubscribeDbPeer === 'function') {
      peer.unsubscribeDbPeer();
    }
    if (typeof peer.unsubscribeDbIce === 'function') {
      peer.unsubscribeDbIce();
    }

    peer.peerConnection.close();

    peer.connectionRef.delete();

    delete this.peers[id];
  }

  hangUp() {
    Object.keys(this.peers).forEach((id) => this.handleDisconnect(id));
    this.messages = [];
    this.cleanUp();
  }

  cleanUp() {
    if (typeof this.unsubscribeDb === 'function') {
      this.unsubscribeDb();
    }

    if (this.userStatusDatabaseRef) {
      this.userStatusDatabaseRef.remove();
      this.userStatusDatabaseRef = null;
    }

    // Delete room on hangup
    if (this.roomId) {
      // const roomRef = firestore.collection('rooms').doc(this.roomId)
      // const memberRef = roomRef.collection('members').doc(this.userId)
      // const connections = await memberRef.collection('connections').get()
      // connections.forEach(async connection => {
      //   const calleeCandidates = await roomRef.collection('calleeCandidates').get()
      //   calleeCandidates.forEach(async candidate => {
      //     await candidate.ref.delete()
      //   })
      //   const callerCandidates = await roomRef.collection('callerCandidates').get()
      //   callerCandidates.forEach(async candidate => {
      //     await candidate.ref.delete()
      //   })
      //   connection.ref.delete()
      // })
      // await memberRef.delete()
      this.roomId = null;
      this.callback();
    }
  }
}
