import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Form, Modal, ModalBody, CardDeck, Card,
} from 'react-bootstrap';
import FormControl from 'react-bootstrap/FormControl';
import ReactStars from 'react-stars';

import { createRoom } from '../helpers/call';
import sendResponses from '../helpers/survey';

import NickPicture from '../assets/img/nick.jpg';
import NikhilPicture from '../assets/img/nikhil.jpg';
import MichellePicture from '../assets/img/michelle.jpg';
import RichardPicture from '../assets/img/richard.jpg';

export default class NewHome extends Component {
  constructor(props) {
    super(props);
    this.handleCreateRoom = this.handleCreateRoom.bind(this);
    this.handleJoinRoom = this.handleJoinRoom.bind(this);
    this.handleRejoinRoom = this.handleRejoinRoom.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalShow = this.handleModalShow.bind(this);
    this.handleSubmitSurvey = this.handleSubmitSurvey.bind(this);
    this.state = {
      modalOpen: false,
      roomId: '',
      formValidated: false,
    };
  }

  componentDidMount() {
    const { survey } = this.props;
    // this.addScript('../assets/vendor/jquery/jquery.min.js')
    // this.addScript('../assets/vendor/bootstrap/js/bootstrap.bundle.min.js')
    // this.addScript('../assets/vendor/jquery-easing/jquery.easing.min.js')
    this.addScript('../assets/js/new-age.min.js');
    if (survey) {
      this.setState({ surveyModalOpen: true });
    }
  }

  componentWillUnmount() {
    if (this.script) {
      this.script.remove();
    }
  }

  addScript(url) {
    this.script = document.createElement('script');
    this.script.src = url;
    document.body.appendChild(this.script);
  }

  async handleCreateRoom() {
    const { goRoom } = this.props;
    const roomId = await createRoom();
    goRoom(roomId);
  }

  handleJoinRoom(e) {
    const { goRoom } = this.props;
    const { roomId } = this.state;
    e.preventDefault();
    if (roomId) {
      goRoom(roomId);
    } else {
      this.setState({ formValidated: true });
    }
  }

  handleRejoinRoom() {
    const { survey, goRoom } = this.props;
    if (survey) {
      goRoom(survey);
    }
  }

  handleSubmitSurvey(e) {
    const { stars, feedback } = this.state;
    e.preventDefault();
    sendResponses(stars, feedback);
    this.setState({ surveyModalOpen: false });
  }

  handleModalClose() {
    this.setState({ modalOpen: false });
  }

  handleModalShow() {
    this.setState({ modalOpen: true });
  }

  render() {
    const {
      modalOpen,
      roomId,
      formValidated,
      surveyModalOpen,
      stars,
    } = this.state;
    return (
      <>
        <Modal show={modalOpen} onHide={this.handleModalClose}>
          <Form noValidate onSubmit={this.handleJoinRoom}>
            <Modal.Header closeButton>
              <Modal.Title>Join Room</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Label>Enter Room ID:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Room ID"
                required
                value={roomId}
                isInvalid={!roomId && formValidated}
                onChange={(event) => this.setState({ roomId: event.target.value })}
              />
              <FormControl.Feedback type="invalid">
                Please enter a Room ID.
              </FormControl.Feedback>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleModalClose}>
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Join Room
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal show={surveyModalOpen} onHide={() => { this.setState({ surveyModalOpen: false }); }}>
          <Form noValidate onSubmit={this.handleSubmitSurvey}>
            <Modal.Header closeButton>
              <Modal.Title>Thanks!</Modal.Title>
            </Modal.Header>
            <ModalBody>
              <Form.Label className="mb-1">
                How was your calling experience? (5 = excellent, 1 = poor)
              </Form.Label>
              <div className="d-flex justify-content-center">
                <ReactStars
                  count={5}
                  value={stars}
                  onChange={(value) => this.setState({ stars: value })}
                  size={30}
                  color1="gray"
                  color2="#007bff"
                  half={false}
                />
              </div>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Any other feedback or concerns?</Form.Label>
                <Form.Control as="textarea" rows="2" onChange={(e) => this.setState({ feedback: e.target.value })} />
              </Form.Group>
            </ModalBody>
            <Modal.Footer>
              <Button variant="secondary" className="mr-auto" onClick={this.handleRejoinRoom}>
                Rejoin
              </Button>
              <Button variant="primary" type="submit">
                Done
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
          <div className="container">
            <a className="navbar-brand js-scroll-trigger" href="#page-top">Pari</a>
            <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
              {'Menu '}
              <i className="fas fa-bars" />
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a className="nav-link js-scroll-trigger" href="#page-top">Home</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link js-scroll-trigger" href="#features">Features</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link js-scroll-trigger" href="#cta">Contact</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link js-scroll-trigger" href="#download">Team</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/security">Security</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://medium.com/pariorg">Blog</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <header className="masthead" id="page-top">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-lg-5 my-auto">
                <div className="header-content mx-auto">
                  <h1 className="mb-2">Pari is a free and secure peer-to-peer video calling platform </h1>
                  <p> No download needed. Browser-based calls for mobile-friendly and group conversations without compromising privacy.</p>
                  <div className="btn btn-outline btn-xl m-2" onClick={this.handleCreateRoom}>Start a Call</div>
                  <div className="btn btn-outline btn-xl m-2" onClick={this.handleModalShow}>Join a Call</div>
                  <br />
                  <br />
                  <p>
                    <a href="/security" rel="noopener noreferrer" target="_blank" className="white">
                      Learn More
                    </a>
                    {' '}
                    About How Pari is Secure
                  </p>
                </div>
              </div>
              <div className="col-lg-7 my-auto pr-0">
                <div className="device-container">
                  <div className="device-mockup macbook landscape white">
                    <div className="device">
                      <div className="button">
                        {/* <!-- You can hook the "home button" to some JavaScript events or just remove it --> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <section className="features" id="features">
          <div className="container">
            <div className="section-heading text-center">
              <h2>Pari is Reimagining Video Calls</h2>
              <p className="text-muted">A not-for-profit and surveillance-free platform accessible to all</p>
              <hr />
            </div>
            <div className="row">
              <div className="col-lg-4 my-auto">
                <div className="device-container">
                  <div className="device-mockup iphone6_plus portrait white">
                    <div className="device">
                      <div className="screen">
                        {/* <!-- Demo image for screen mockup, you can put an image here, some HTML, an animation, video, or anything else! --> */}
                      </div>
                      <div className="button">
                        {/* <!-- You can hook the "home button" to some JavaScript events or just remove it --> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 my-auto">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="feature-item">
                        <i className="icon-lock text-primary" />
                        <h3>Privacy-First Design</h3>
                        <p className="text-muted">Host secure conversations without going through a central server</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="feature-item">
                        <i className="icon-screen-smartphone text-primary" />
                        <h3>Mobile Friendly</h3>
                        <p className="text-muted">Call from your laptop or phone straight from your browser</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="feature-item">
                        <i className="icon-people text-primary" />
                        <h3>Make it a Party</h3>
                        <p className="text-muted">Create a room and bring everyone together with group calls</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="feature-item">
                        <i className="icon-doc text-primary" />
                        <h3>File Sharing</h3>
                        <p className="text-muted">Transfer files securely to friends and colleagues</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container h-100">
            <div className="row pt-5 pb-3">
              <div className="col-lg-12 my-auto">
                <div className="header-content mx-auto text-center">
                  <h1>
                    <b> Coming Soon to Pari </b>
                  </h1>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="feature-item">
                  <i className="icon-layers text-primary" />
                  <h3> AI Enabled  </h3>
                  <p className="text-muted"> Machine learning for enhanced video and lighting  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="feature-item">
                  <i className="icon-star text-primary" />
                  <h3> Better Mobile </h3>
                  <p className="text-muted">Improved performance across different mobile browsers</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="feature-item">
                  <i className="icon-login text-primary" />
                  <h3> Accounts </h3>
                  <p className="text-muted">Make direct calls without creating a room</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="feature-item">
                  <i className="icon-ban text-primary" />
                  <h3> Request to Join </h3>
                  <p className="text-muted">Approve who can and cannot join a call</p>
                </div>
              </div>
            </div>
            <div className="row pt-5">
              <div className="col-lg-12 my-auto">
                <div className="header-content mx-auto text-center">
                  <h3>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfvYxa7HFmh9nb56Sz6otEWus3gf2PloSz3mzK7ioUlxYZ57g/viewform" rel="noopener noreferrer" target="_blank">
                      <b>Join the Pari Team</b>
                    </a>
                    {' '}
                    and Help Build These Features
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="cta" id="cta">
          <div className="cta-content">
            <div className="container">
              <h2>We’d Love to Hear from You!</h2>
              <a href="mailto:contact@paricall.org" className="btn btn-outline btn-xl js-scroll-trigger">Contact Us</a>
            </div>
          </div>
          <div className="overlay" />
        </section>

        <section className="download text-center" id="download">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mx-auto">
                <h2 className="section-heading mb-0">We Believe in Tech for Good</h2>
                <h3>
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSfvYxa7HFmh9nb56Sz6otEWus3gf2PloSz3mzK7ioUlxYZ57g/viewform" rel="noopener noreferrer" target="_blank">
                    <b>Join</b>
                  </a>
                  {' '}
                  the Movement
                </h3>
                <CardDeck className="my-4">
                  <Card className="bio">
                    <Card.Img className="bioimage" variant="top" src={NickPicture} alt="NICHOLAS GUO" />
                    <Card.Body className="biotextcontainer p-2 overflow-auto">
                      <h3 className="my-2 text-left">
                        NICHOLAS GUO
                        <br />
                      </h3>
                      <Card.Text className="my-2 biotext">
                        <b> Technology and Product </b>
                      </Card.Text>
                      <Card.Text className="my-2 text-left biotext">
                        Nicholas is a recent grad of MIT in computer science. His interests are in building and designing technology for good, and his work focuses on security and distributed systems. He has written software for Algorand, Celo, Quora, Five Rings Capital, and Addepar.
                        <br />
                      </Card.Text>
                      <a href="https://www.linkedin.com/in/nicholas-guo-071993138/" rel="noopener noreferrer" target="_blank">LinkedIn</a>
                    </Card.Body>
                  </Card>
                  <Card className="bio">
                    <Card.Img className="bioimage" variant="top" src={NikhilPicture} alt="NIKHIL RAGHUVEERA" />
                    <Card.Body className="biotextcontainer p-2 overflow-auto">
                      <h3 className="my-2 text-left">
                        NIKHIL RAGHUVEERA
                        <br />
                      </h3>
                      <Card.Text className="my-2 biotext">
                        <b> Strategy and Policy </b>
                      </Card.Text>
                      <Card.Text className="my-2 text-left biotext">
                        Nikhil is a Consultant at the Boston Consulting Group and a Fellow at the Atlantic Council GeoTech Center, where his research focuses on the intersection of technology, social inequality, and systems of oppression.
                        <br />
                        <br />
                        Nikhil received his MBA from The Wharton School and MPA from the Harvard Kennedy School.
                        <br />
                      </Card.Text>
                      <a href="https://www.linkedin.com/in/nikhil-raghuveera-15927636/" rel="noopener noreferrer" target="_blank">LinkedIn</a>
                    </Card.Body>

                  </Card>
                  <Card className="bio">
                    <Card.Img className="bioimage" variant="top" src={MichellePicture} alt="MICHELLE CASTILLO" />
                    <Card.Body className="biotextcontainer p-2 overflow-auto">
                      <h3 className="my-2 text-left">
                        MICHELLE CASTILLO
                        <br />
                      </h3>
                      <Card.Text className="my-2 biotext">
                        <b> Partnerships and Product </b>
                      </Card.Text>
                      <Card.Text className="my-2 text-left biotext">
                        Michelle is an experienced Technology Solutions Consultant with a demonstrated history of working in the enterprise and big tech industry. Areas of focus include technical feasibility, operations, and business strategy.
                        <br />
                        <br />
                        Michelle received her BA from the University of Florida.
                        <br />
                      </Card.Text>
                      <a href="https://www.linkedin.com/in/michelle-castillo-030222121/" rel="noopener noreferrer" target="_blank">LinkedIn</a>
                    </Card.Body>
                  </Card>
                  <Card className="bio">
                    <Card.Img className="bioimage" variant="top" src={RichardPicture} alt="RICHARD YANG" />
                    <Card.Body className="biotextcontainer p-2 overflow-auto">
                      <h3 className="my-2 text-left">
                        RICHARD YANG
                        <br />
                      </h3>
                      <Card.Text className="my-2 biotext">
                        <b> Technology and Product </b>
                      </Card.Text>
                      <Card.Text className="my-2 text-left biotext">
                        Richard is a senior at UCLA studying computer science. He enjoys working with web technologies and creating delightful digital experiences. He has most recently worked for Wealthfront.
                        <br />
                      </Card.Text>
                      <a href="https://www.linkedin.com/in/ryang72/" rel="noopener noreferrer" target="_blank">LinkedIn</a>
                    </Card.Body>
                  </Card>
                </CardDeck>
              </div>
            </div>
          </div>
        </section>

        <footer>
          <div className="container">
            <p>&copy; Pari. All Rights Reserved.</p>
            <ul className="list-inline">
              <li className="list-inline-item">
                <a href="javascript:;" onClick={this.handleCreateRoom}>Launch Pari</a>
              </li>
              |
              <li className="list-inline-item">
                <a className="js-scroll-trigger" href="#features">Features</a>
              </li>
              |
              <li className="list-inline-item">
                <a className="js-scroll-trigger" href="#cta">Contact</a>
              </li>
              |
              <li className="list-inline-item">
                <a className="js-scroll-trigger" href="#download">Team</a>
              </li>
              |
              <li className="list-inline-item">
                <a href="/security">Security</a>
              </li>
              |
              <li className="list-inline-item">
                <a href="https://medium.com/pariorg">Blog</a>
              </li>
            </ul>
          </div>
        </footer>
      </>
    );
  }
}

NewHome.defaultProps = {
  survey: false,
  goRoom: () => {},
};

NewHome.propTypes = {
  survey: PropTypes.bool,
  goRoom: PropTypes.func,
};
