import firebase from 'firebase/app';
// import 'firebase/auth'
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyBRbP53MqZZllDGNpzIVRihgT28hGyF01Y',
  authDomain: 'paricall.firebaseapp.com',
  databaseURL: 'https://paricall.firebaseio.com',
  projectId: 'paricall',
  storageBucket: 'paricall.appspot.com',
  messagingSenderId: '58660233192',
  appId: '1:58660233192:web:b50637a6078b1c453b0e5d',
  measurementId: 'G-QXRCL6SWGS',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// export const auth = firebase.auth;
export const firestore = firebase.firestore();
export const db = firebase.database();
