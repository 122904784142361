import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, Overlay, Tooltip } from 'react-bootstrap';
import { FaCopy } from 'react-icons/fa';

const Line = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
`;

const Icon = styled(FaCopy)`
  font-size: 1.25rem;
  margin-left: 0.5rem;
`;

export default class CopyURL extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showToolTip: false,
    };
    this.handleCopy = this.handleCopy.bind(this);
    this.ref = React.createRef();
  }

  handleCopy() {
    const dummy = document.createElement('input');
    const text = window.location.href;
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    this.setState({ showToolTip: true });
    setTimeout(() => this.setState({ showToolTip: false }), 1000);
  }

  render() {
    const { className, placement, size } = this.props;
    const { showToolTip } = this.state;
    return (
      <>
        <Button
          variant="outline-dark"
          onClick={this.handleCopy}
          className={className}
          ref={this.ref}
          size={size}
        >
          <Line onClick={this.handleCopy}>
            {'Copy Join Link '}
            <Icon />
          </Line>
        </Button>
        <Overlay
          placement={placement}
          show={showToolTip}
          target={this.ref.current}
        >
          <Tooltip>Join link copied</Tooltip>
        </Overlay>
      </>
    );
  }
}

CopyURL.defaultProps = {
  placement: undefined,
  size: undefined,
  className: '',
};

CopyURL.propTypes = {
  placement: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
};
