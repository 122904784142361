import { iceConfiguration, black } from './globals';

const BYTES_PER_CHUNK = 16000;
const MAX_BUFFER_BYTES = 1000000;

export default class Peer {
  constructor(peerId, name, stream, callback) {
    this.peerId = peerId;
    this.name = name;
    this.peerConnection = new RTCPeerConnection(iceConfiguration);
    this.videoSender = null;
    this.audioSender = null;
    this.statusChannel = null;
    this.fileChannel = null;
    this.stream = new MediaStream();
    this.localStream = stream;
    this.unsubscribeDbPeer = null;
    this.unsubscribeDbIce = null;
    this.videoMuted = false;
    this.audioMuted = false;
    this.sendVideo = true;
    this.receiveVideo = true;
    this.callback = callback;
    this.files = [];
    this.iceCandidates = [];
  }

  ready() {
    if (this.peerConnection.iceConnectionState === 'connected') { return true; }
    if (this.peerConnection.iceConnectionState === 'completed') { return true; }
    if (this.peerConnection.iceConnectionState === 'disconnected' && this.connected === true) { return true; }
    if (this.peerConnection.connectionState === 'connected') { return true; }
    return false;
  }

  async updateVideoQuality(videoBitrate) {
    if (this.ready() && this.videoSender.setParameters) {
      const parameters = this.videoSender.getParameters();
      if (!parameters.encodings) {
        parameters.encodings = [{}];
      }
      parameters.encodings[0].maxBitrate = this.sendVideo ? videoBitrate * 1000 : 1;
    }
  }

  sendChat(message) {
    const channel = this.chatChannel;
    if (channel && channel.readyState !== 'open') {
      // TODO: code to try this again
      return;
    }
    channel.send(message);
  }

  sendFile(file, incrementPeerProgress) {
    const channel = this.fileChannel;
    if (channel && channel.readyState !== 'open') {
      // TODO: code to try this again
      // TODO: need to encode 'failed to send to x' here instead
      incrementPeerProgress(1);
      return;
    }
    const fileReader = new FileReader();

    let currentChunk = 0;
    const MAX_CHUNK = Math.ceil(file.size / BYTES_PER_CHUNK);
    // send some metadata about our file to the receiver
    this.fileChannel.send(JSON.stringify({
      fileName: file.name,
      fileSize: file.size,
    }));

    function readNextChunk() {
      const start = BYTES_PER_CHUNK * currentChunk;
      const end = Math.min(file.size, start + BYTES_PER_CHUNK);
      fileReader.readAsArrayBuffer(file.slice(start, end));
    }

    function sendNextChunk() {
      if (channel.bufferedAmount > MAX_BUFFER_BYTES) {
        setTimeout(sendNextChunk, 300);
        return;
      }
      channel.send(fileReader.result);
      currentChunk += 1;
      incrementPeerProgress(1 / MAX_CHUNK);

      if (BYTES_PER_CHUNK * currentChunk < file.size) {
        readNextChunk();
      } else {
        incrementPeerProgress(1);
      }
    }

    fileReader.onload = sendNextChunk;

    readNextChunk();
  }

  startDownload(data) {
    this.incomingFileInfo = JSON.parse(data.toString());
    this.incomingFileData = [];
    this.bytesReceived = 0;
    this.downloadInProgress = true;
    console.log(`incoming file <b>${this.incomingFileInfo.fileName}</b> of ${this.incomingFileInfo.fileSize} bytes`);
  }

  progressDownload(data) {
    this.bytesReceived += data.byteLength;
    this.incomingFileData.push(data);
    console.log(`progress: ${((this.bytesReceived / this.incomingFileInfo.fileSize) * 100).toFixed(2)}%`);
    if (this.bytesReceived === this.incomingFileInfo.fileSize) {
      this.endDownload();
    }
  }

  endDownload() {
    this.downloadInProgress = false;
    const blob = new window.Blob(this.incomingFileData);
    this.files.push({ name: this.incomingFileInfo.fileName, href: URL.createObjectURL(blob) });
    this.callback({
      id: this.peerId,
      message: this.incomingFileInfo.fileName,
      href: URL.createObjectURL(blob),
      senderName: this.name,
    });
  }

  handleFileMessage() {
    const peer = this;
    return (event) => {
      const { data } = event;
      console.log('file data', peer.downloadInProgress, peer.peerId, data);
      if (peer.downloadInProgress) {
        peer.progressDownload(data);
      } else {
        peer.startDownload(data);
      }
    };
  }

  handleStatusMessage() {
    const peer = this;
    return (event) => {
      const message = event.data;
      console.log('status', peer.peerId, message);
      switch (message) {
        case 'disconnect':
          peer.onDisconnect();
          break;
        case 'videomute':
          peer.videoMuted = true;
          peer.shareScreen = undefined;
          break;
        case 'videounmute':
          peer.videoMuted = false;
          break;
        case 'audiomute':
          peer.audioMuted = true;
          break;
        case 'audiounmute':
          peer.audioMuted = false;
          break;
        case 'startsharescreen':
          peer.shareScreen = new Date().getTime();
          break;
        case 'endsharescreen':
          peer.shareScreen = undefined;
          break;
        case 'sendvideo':
          if (!peer.sendVideo) {
            peer.videoSender.replaceTrack(peer.localStream.getVideoTracks()[0]);
            peer.sendVideo = true;
          } else {
            return;
          }
          break;
        case 'stopvideo':
          if (peer.sendVideo) {
            peer.videoSender.replaceTrack(black());
            peer.sendVideo = false;
          } else {
            return;
          }
          break;
        default:
      }
      peer.callback();
    };
  }

  handleChatMessage() {
    const peer = this;
    return (event) => {
      const message = event.data;
      console.log('chat', peer.peerId, message);
      peer.callback({
        id: peer.peerId,
        message,
        senderName: this.name,
      });
    };
  }

  setStatusListeners(handleDisconnect) {
    this.peerConnection.oniceconnectionstatechange = () => {
      if (this) {
        console.log(this.peerId, this.peerConnection.iceConnectionState);
        switch (this.peerConnection.iceConnectionState) {
          case 'connected':
          case 'completed':
            if (typeof this.unsubscribeDbPeer === 'function') {
              this.unsubscribeDbPeer();
              delete this.unsubscribeDbPeer;
            }
            console.log('Successfully connected to: ', this.peerId);
            break;
          case 'closed':
          case 'failed':
            handleDisconnect(this.peerId);
            break;
          default:
        }
        this.callback();
      }
    };
    this.peerConnection.onconnectionstatechange = () => {
      if (this) {
        console.log('connection state ', this.peerConnection.connectionState);
        switch (this.peerConnection.connectionState) {
          case 'failed':
          case 'closed':
            console.log('connection state closed');
            handleDisconnect(this.peerId);
            break;
          default:
        }
        this.callback();
      }
    };
    this.peerConnection.onsignalingstatechange = () => {
      if (this) {
        console.log('signalling state ', this.peerConnection.signalingState);
        switch (this.peerConnection.signalingState) {
          case 'closed':
            console.log('signaling state closed');
            handleDisconnect(this.peerId);
            break;
          default:
        }
        this.callback();
      }
    };
  }
}
