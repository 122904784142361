export default {
  chatbubbleWrapper: {
    overflow: 'auto',
  },
  chatbubble: {
    backgroundColor: '#0099FF',
    borderRadius: 18,
    marginTop: 1,
    marginRight: 'auto',
    // marginBottom: 1,
    marginLeft: 'auto',
    maxWidth: '90%',
    paddingTop: 6,
    paddingBottom: 7,
    paddingLeft: 12,
    paddingRight: 12,
    width: '-webkit-fit-content',
    wordWrap: 'break-word',
  },
  chatbubbleOrientationNormal: {
    float: 'right',
  },
  recipientChatbubble: {
    backgroundColor: '#ddd',
  },
  recipientChatbubbleOrientationNormal: {
    float: 'left',
  },
  p: {
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: '300',
    margin: 0,
  },
  recipientp: {
    color: '#000000'
  }
};
