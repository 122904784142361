import { detect } from 'detect-browser';
import { firestore } from '../services/firebase';

const browser = detect();

export default async function sendResponses(stars, feedback) {
  const response = {};
  let send = false;
  if (stars) {
    response.stars = stars;
    send = true;
  }
  if (feedback) {
    response.feedback = feedback;
    send = true;
  }
  if (send) {
    response.browser = `{name: ${browser.name}, version: ${browser.version}, os: ${browser.os}}`;
    const surveyRef = await firestore.collection('survey').doc();
    await surveyRef.set(response);
    console.log('logged');
  }
}
