import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Form, Modal } from 'react-bootstrap';
import { ChatFeed, ChatInput } from './chat-ui';
import Caller from '../helpers/call';

const Boundary = styled.div`
  height: 100%;
  width: ${({ show, width }) => (show ? width || '100%' : '1px')};
  max-width: 100%;
  padding-right: ${({ show, width }) => (show && width ? 5 : 0)}px;
  padding-left: ${({ show, width }) => (show && width ? 5 : 0)}px;
  border-left: ${({ show, width }) => (show && width ? 1 : 0)}px solid rgba(0, 0, 0, 0.3);
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;
`;

export default class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      progress: 0,
    };
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleFileProgress = this.handleFileProgress.bind(this);
    this.handleMessageSubmit = this.handleMessageSubmit.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalShow = this.handleModalShow.bind(this);
  }

  handleFileChange(e) {
    this.setState({ file: e.target.files[0] });
  }

  handleFileUpload(e) {
    const { caller } = this.props;
    const { file } = this.state;
    e.preventDefault();
    if (file) {
      caller.sendFile(file, this.handleFileProgress);
      this.setState({ file: null });
      this.handleModalClose();
    }
  }

  handleFileProgress(newProgressValue) {
    this.setState((prevState) => ({ progress: prevState.progress + newProgressValue >= 1 ? 0 : prevState.progress + newProgressValue }));
  }

  handleMessageSubmit(e) {
    const { caller } = this.props;
    e.preventDefault();
    const input = e.currentTarget.elements[0];
    if (input.value.trim()) {
      caller.sendChat(input.value.trim());
      input.value = null;
    }
    input.focus();
  }

  handleModalClose() {
    this.setState({ modalOpen: false });
  }

  handleModalShow() {
    this.setState({ modalOpen: true });
  }

  render() {
    const {
      caller, width, show, mobile, close, maxHeight,
    } = this.props;
    const { modalOpen, progress } = this.state;
    return (
      <Boundary show={show} width={width}>
        <div>
          <h3 style={{
            borderBottomWidth: '1',
            borderBottomStyle: 'solid',
            borderBottomColor: '#ddd',
            textAlign: 'center',
          }}
          >
            Chat
          </h3>
          {!mobile && close && show
            && (
              <button type="button" className="close" style={{ position: 'absolute', top: 0, right: 10 }} onClick={close}>
                <span aria-hidden="true">×</span>
                <span className="sr-only">Close</span>
              </button>
            )}
        </div>
        <ChatFeed
          // chatBubble={this.state.useCustomBubble && customBubble}
          maxHeight={maxHeight}
          messages={caller.messages} // Boolean: list of message objects
          showSenderName
        />
        <ChatInput onFileUpload={this.handleModalShow} onMessageSubmit={this.handleMessageSubmit} inputPlaceholder="Type a message..." fileProgress={progress} overlay={!mobile} />
        <Modal show={modalOpen} onHide={this.handleModalClose}>
          <Form onSubmit={this.handleFileUpload}>
            <Modal.Header closeButton>
              <Modal.Title>Upload a file</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.File required onChange={this.handleFileChange} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleModalClose}>
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Upload
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </Boundary>
    );
  }
}

Chat.defaultProps = {
  caller: null,
  show: false,
  close: () => { },
  width: undefined,
  maxHeight: '100%',
  mobile: false,
};

Chat.propTypes = {
  caller: PropTypes.instanceOf(Caller),
  show: PropTypes.bool,
  close: PropTypes.func,
  width: PropTypes.string,
  maxHeight: PropTypes.string,
  mobile: PropTypes.bool,
};
