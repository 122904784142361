import * as React from 'react';
// import ChatBubbleProps from './interface';
import Linkify from 'react-linkify';
import { FaDownload } from 'react-icons/fa';
import styles from './styles';

const defaultBubbleStyles = {
  userBubble: {},
  chatbubble: {},
  text: {},
};

const componentDecorator = (style) => (
  decoratedHref,
  decoratedText,
  key,
) => (
  <a href={decoratedHref} key={key} style={{ ...style, textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">
    {decoratedText}
  </a>
);

function renderMsg(message, textStyle) {
  if (message.href) {
    if (message.message.match(/.(jpg|jpeg|png|gif)$/i)) {
      return (
        <img src={message.href} download={message.message} alt={message.message} style={{ width: '100%' }} />
      );
    }
    return (
      <a href={message.href} download={message.message} style={textStyle}>
        <FaDownload />
        {' '}
        {message.message}
      </a>
    );
  }
  return (
    <Linkify style={textStyle} componentDecorator={componentDecorator(textStyle)}>
      <p style={textStyle}>{message.message}</p>
    </Linkify>
  );
}

export default class ChatBubble extends React.Component {
  render() {
    const { bubblesCentered } = this.props;
    let { bubbleStyles } = this.props;
    bubbleStyles = bubbleStyles || defaultBubbleStyles;
    const { userBubble, chatbubble, text } = bubbleStyles;

    // message.id 0 is reserved for blue
    const chatBubbleStyles = this.props.message.id === 0
      ? {
        ...styles.chatbubble,
        ...bubblesCentered ? {} : styles.chatbubbleOrientationNormal,
        ...chatbubble,
        ...userBubble,
      }
      : {
        ...styles.chatbubble,
        ...styles.recipientChatbubble,
        ...bubblesCentered
          ? {}
          : styles.recipientChatbubbleOrientationNormal,
        ...chatbubble,
        ...userBubble,
      };

    const textStyle = this.props.message.id === 0
      ? { ...styles.p, ...text }
      : { ...styles.p, ...styles.recipientp, ...text };

    return (
      <div
        style={{
          ...styles.chatbubbleWrapper,
        }}
      >
        <div style={chatBubbleStyles}>
          { renderMsg(this.props.message, textStyle)}
        </div>
      </div>
    );
  }
}

// export { ChatBubbleProps };
