import React, { Component } from 'react';
import styled from 'styled-components';
import {
  Button, Container, Form, Jumbotron, Modal, ModalBody,
} from 'react-bootstrap';
import FormControl from 'react-bootstrap/FormControl';
import ReactStars from 'react-stars';
import Logo from '../assets/logo.png';
import homeVideo from '../assets/video.mp4';

import { createRoom } from '../helpers/call';
import sendResponses from '../helpers/survey';

const APP_NAME = 'P2Pmeetings';

const Title = styled.div`
  padding: 0rem 0.5rem 0rem 0.5rem;
  background-color: white;
  display: flex;
  align-items: center;
`;

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.handleCreateRoom = this.handleCreateRoom.bind(this);
    this.handleJoinRoom = this.handleJoinRoom.bind(this);
    this.handleRejoinRoom = this.handleRejoinRoom.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalShow = this.handleModalShow.bind(this);
    this.handleSubmitSurvey = this.handleSubmitSurvey.bind(this);
    this.handleTestNotifications = this.handleTestNotifications.bind(this);
    this.state = {
      modalOpen: false,
      roomId: '',
      formValidated: false,
    };
  }

  componentDidMount() {
    if (this.props.survey) {
      this.setState({ surveyModalOpen: true });
    }
    console.log(this.props.survey);
  }

  async handleCreateRoom() {
    this.setState({ loading: 'create' });
    const roomId = await createRoom();
    this.setState({ loading: null });
    this.props.goRoom(roomId);
  }

  handleJoinRoom(e) {
    e.preventDefault();
    if (this.state.roomId) {
      this.props.goRoom(this.state.roomId);
    } else {
      this.setState({ formValidated: true });
    }
  }

  handleRejoinRoom() {
    if (this.props.survey) {
      this.props.goRoom(this.props.survey);
    }
  }

  handleSubmitSurvey(e) {
    e.preventDefault();
    sendResponses(this.state.stars, this.state.feedback);
    this.setState({ surveyModalOpen: false });
  }

  handleModalClose() {
    this.setState({ modalOpen: false });
  }

  handleModalShow() {
    this.setState({ modalOpen: true });
  }

  handleTestNotifications() {
    const randomNotification = () => {
      const randomItem = Math.floor(Math.random() * 5);
      new Notification(randomItem, {});
      setTimeout(randomNotification, 30000);
    };
    Notification.requestPermission().then((result) => {
      alert(result);
      if (result === 'granted') {
        randomNotification();
      }
    });
  }

  render() {
    console.log(this.props);
    return (
      <>
        <Title>
          <img src={Logo} alt="logo" className="p-0" style={{ height: '4rem', width: '4rem' }} />
          <h1 className="px-2 my-auto" style={{ color: '#4c96d7' }}>{APP_NAME}</h1>
        </Title>
        <Jumbotron className="py-4 m-0">
          <h3 className="m-0">
            Free secure peer to peer communications. Video chat, send messages, or transfer files directly to others.
            <b> No middlemen, no data collection, no bullshit. </b>
          </h3>
        </Jumbotron>
        <Container className="h-100 overflow-auto pb-2 d-flex flex-column align-items-center">
          <video src={homeVideo} autoPlay playsInline muted loop id="localVideo" style={{ maxWidth: '100%' }} />
          <i>Not ideal to have someone in between your conversations, is it?</i>
          <div className="mb-2 d-flex">
            <Button className="m-2" variant="primary" onClick={this.handleCreateRoom} disabled={this.state.loading === 'create'}>
              {this.state.loading === 'create' ? 'Creating room...' : 'Create room'}
            </Button>
            {' '}
            <Button className="m-2" variant="primary" onClick={this.handleModalShow}>
              Join room
            </Button>
            {' '}
            <Button className="m-2" variant="primary" onClick={this.handleTestNotifications}>
              Test Notifications
            </Button>
          </div>
          <Modal show={this.state.modalOpen} onHide={this.handleModalClose}>
            <Form noValidate onSubmit={this.handleJoinRoom}>
              <Modal.Header closeButton>
                <Modal.Title>Join Room</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Label>Enter Room ID:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Room ID"
                  required
                  value={this.state.roomId}
                  isInvalid={!this.state.roomId && this.state.formValidated}
                  onChange={(event) => this.setState({ roomId: event.target.value })}
                />
                <FormControl.Feedback type="invalid">
                  Please enter a Room ID.
                </FormControl.Feedback>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleModalClose}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit">
                  Join Room
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <Modal show={this.state.surveyModalOpen} onHide={() => { this.setState({ surveyModalOpen: false }); }}>
            <Form noValidate onSubmit={this.handleSubmitSurvey}>
              <Modal.Header closeButton>
                <Modal.Title>Thanks!</Modal.Title>
              </Modal.Header>
              <ModalBody>
                <Form.Label className="mb-1">
                  How was your calling experience? (5 = excellent, 1 = poor)
                </Form.Label>
                <div className="d-flex justify-content-center">
                  <ReactStars
                    count={5}
                    value={this.state.stars}
                    onChange={(value) => this.setState({ stars: value })}
                    size={30}
                    color1="gray"
                    color2="#007bff"
                    half={false}
                  />
                </div>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Any other feedback or concerns?</Form.Label>
                  <Form.Control as="textarea" rows="2" onChange={(e) => this.setState({ feedback: e.target.value })} />
                </Form.Group>
              </ModalBody>
              <Modal.Footer>
                <Button variant="secondary" className="mr-auto" onClick={this.handleRejoinRoom}>
                  Rejoin
                </Button>
                <Button variant="primary" type="submit">
                  Done
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

        </Container>
      </>
    );
  }
}
