import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ResizeObserver from 'resize-observer-polyfill';
import Peer from '../helpers/peer';

import {
  MuteIcon, TalkingIcon, CameraOffIcon, FullScreenIcon,
} from './Icons';

export const VideosWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  flex: 1 1 0px;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
`;

export const VideosOverflow = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  flex-direction: ${({ direction }) => direction};
  overflow-y: auto;
  ${({ direction }) => (direction === 'column' ? 'height: 100%; width: 280px;' : 'width: 100%; height: 210px;')}
  ${({ direction }) => (direction === 'column' && 'margin-bottom: 1rem;')}
  align-items: center;
  ${({ direction }) => (direction === 'column' && 'border-left: 1px solid rgba(0, 0, 0, 0.3);')}
`;

const VideoFrame = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  position: relative;
  background: black;
  max-width: 100%;
  width: ${({ videoWidth }) => videoWidth};
  height: 0px;
  padding-bottom: ${({ aspectRatio, videoWidth }) => `calc(${aspectRatio} * ${videoWidth})`};
  justify-content: center;
  align-items: start;
  overflow: hidden;
  border-radius: 0.25rem;
  margin-bottom: 1px;
`;

const VideoObject = styled.video`
  position: absolute;
  pointer-events: none;
  ${({ shareScreen }) => (shareScreen ? 'width: 100%' : 'height: 100%')};
  ${({ videoMuted }) => (videoMuted ? 'display: none;' : 'display: block;')}
  ${({ flipped }) => flipped && `
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  `}
`;

const IconContainer = styled.div`
  background-color:rgba(0, 0, 0, ${({ weight }) => weight || 0.1});
  border-radius: 0.25rem;
  height: calc(10px + 2rem);
  width: calc(10px + 2rem);
  position: absolute;
  top: ${({ mobile }) => (mobile ? '30px' : '0px')};
  ${({ index }) => {
    if (index >= 0) {
      return `left: calc(${index} * (10px + 2rem) + 5px);`;
    }
    return `right: calc(${-1 - index} * (10px + 2rem) + 5px);`;
  }}
  display: flex;
  justify-content: center;
  align-items: center
`;

const NameOverlay = styled.div`
  background-color:rgba(0, 0, 0, 0.4);
  border-radius: 0.25rem;
  position: absolute;
  ${({ mobile }) => (mobile ? 'top' : 'bottom')}: 5px;
  left: 5px;
  color: white;
  padding: 0.1rem 0.5rem 0.1rem 0.5rem;
`;

export default class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.videoRef = React.createRef();
    this.audioRef = React.createRef();
    this.updateVideoStream = this.updateVideoStream.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleOpenFullscreen = this.handleOpenFullscreen.bind(this);
  }

  componentDidMount() {
    this.updateVideoStream();
    window.addEventListener('focus', this.handleFocus);
    this.setState({ focus: window.document.hasFocus() });
    this.videoRef.current.onPause = this.handleBlur;
    // if (this.props.peer.stream && this.props.peer.stream.getAudioTracks().length) {
    this.initHark();
    // }
    const handleResize = () => {
      if (this.videoRef.current) {
        this.setState({
          videoHeight: this.videoRef.current.clientHeight,
          videoWidth: this.videoRef.current.clientWidth,
        });
      }
    };
    if (ResizeObserver) {
      new ResizeObserver(handleResize).observe(this.videoRef.current);
    }
  }

  componentDidUpdate() {
    const { peer } = this.props;
    this.updateVideoStream();
    if (peer.stream && peer.stream.getAudioTracks().length) {
      // this.initHark();
    }
  }

  componentWillUnmount() {
    const { peer } = this.props;
    window.removeEventListener('focus', this.handleFocus);
    if (peer) {
      const { speechEvents } = peer;
      if (speechEvents) {
        speechEvents.off('speaking');
        speechEvents.off('stopped_speaking');
      }
    }
  }

  initHark() {
    const { peer, onTalk } = this.props;
    const { isTalking } = this.state;
    const setTalking = (newIsTalking) => () => {
      if (onTalk && !isTalking) {
        onTalk();
      }
      this.setState({ isTalking: newIsTalking });
    };
    if (peer) {
      const { speechEvents } = peer;
      if (speechEvents) {
        console.log(peer.peerId, 'init hark');
        speechEvents.off('speaking');
        speechEvents.off('stopped_speaking');
        speechEvents.on('speaking', setTalking(true));
        speechEvents.on('stopped_speaking', setTalking(false));
      }
    }
  }

  handleOpenFullscreen() {
    const elem = this.videoRef.current;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }

  updateVideoStream() {
    const { peer } = this.props;
    if (this.videoRef.current.srcObject !== peer.stream) {
      this.videoRef.current.srcObject = peer.stream;
      this.audioRef.current.srcObject = peer.stream;
      this.initHark();
    }
  }

  async handleFocus() {
    this.setState({ focus: true });
    if (this.videoRef.current && this.videoRef.current.srcObject && this.videoRef.current.paused) {
      await this.videoRef.current.play();
    }
    if (this.audioRef.current && !this.videoRef.current.paused) {
      await this.audioRef.current.pause();
    }
  }

  async handleBlur() {
    const { localVideo } = this.props;
    this.setState({ focus: false });
    if (localVideo && this.audioRef.current && this.videoRef.current && this.videoRef.current.paused) {
      await this.audioRef.current.play();
    }
  }

  renderIcons() {
    const {
      peer,
      noIcons,
      noName,
      mobile,
      name,
    } = this.props;
    const {
      isTalking,
    } = this.state;
    const icons = [];
    if (!noIcons) {
      if (peer.videoMuted && !peer.shareScreen) {
        icons.push(
          <IconContainer key={icons.length} index={icons.length} mobile={mobile}>
            <CameraOffIcon color="b80d0d" fontSize="2rem" />
          </IconContainer>,
        );
      }
      if (peer.audioMuted) {
        icons.push(
          <IconContainer key={icons.length} index={icons.length} mobile={mobile}>
            <MuteIcon color="b80d0d" fontSize="2rem" />
          </IconContainer>,
        );
      }
      if (isTalking) {
        icons.push(
          <IconContainer key={icons.length} index={icons.length} mobile={mobile}>
            <TalkingIcon color="4aad1f" fontSize="2rem" />
          </IconContainer>,
        );
      }
      if (peer.shareScreen && peer.peerId) {
        icons.push(
          <IconContainer key={-1} index={-1} weight="0.4">
            <FullScreenIcon color="white" fontSize="1.5rem" onClick={this.handleOpenFullscreen} mobile={mobile} />
          </IconContainer>,
        );
      }
    }
    if (!noName) {
      icons.push(
        <NameOverlay key="name" mobile={mobile}>{name || peer.name}</NameOverlay>,
      );
    }

    return (
      <>
        {icons}
      </>
    );
  }

  render() {
    const {
      peer,
      noName,
      videoHeight,
      videoWidth,
      hidden,
      muted,
      flipped,
    } = this.props;
    const aspectRatio = (peer.shareScreen || noName) && this.videoRef.current ? this.videoRef.current.clientHeight / this.videoRef.current.clientWidth : 0.75;
    const correctedVideoWidth = videoHeight && aspectRatio && `min(${videoWidth || '300px'}, calc(${videoHeight} / ${aspectRatio}))`;
    return (
      <VideoFrame
        aspectRatio={aspectRatio || 0.75}
        videoWidth={correctedVideoWidth || videoWidth || '300px'}
        hidden={hidden}
      >
        <VideoObject
          key={peer.stream ? peer.stream.id : ''}
          audioMuted={peer.audioMuted}
          videoMuted={peer.videoMuted && !peer.shareScreen}
          shareScreen={peer.shareScreen}
          muted={muted}
          flipped={flipped}
          autoPlay
          playsInline
          ref={this.videoRef}
        />
        <audio
          autoPlay
          playsInline
          paused="true"
          muted={muted}
          ref={this.audioRef}
        />
        {this.renderIcons()}
      </VideoFrame>
    );
  }
}

Video.defaultProps = {
  peer: null,
  onTalk: false,
  muted: false,
  localVideo: false,
  hidden: false,
  videoWidth: undefined,
  videoHeight: undefined,
  flipped: false,
  noName: false,
  noIcons: false,
  mobile: false,
  name: '',
};

Video.propTypes = {
  peer: PropTypes.instanceOf(Peer),
  onTalk: PropTypes.func,
  muted: PropTypes.bool,
  localVideo: PropTypes.bool,
  hidden: PropTypes.bool,
  videoWidth: PropTypes.string,
  videoHeight: PropTypes.string,
  flipped: PropTypes.bool,
  noName: PropTypes.bool,
  noIcons: PropTypes.bool,
  mobile: PropTypes.bool,
  name: PropTypes.string,
};
