import {
  VIDEO_BITRATE,
  callConfig,
  silence,
  black,
  blackSilence,
  initHark,
} from './globals';

export async function createRoom() {
  const codeAlphabet = 'abcdefghijklmnopqrstuvwxyz1234567890';
  let code = '';
  for (let i = 0; i < 10; i += 1) {
    code += codeAlphabet.charAt(Math.floor(Math.random() * codeAlphabet.length));
  }
  return code;
}

export default class Caller {
  constructor() {
    this.peers = {};
    this.messages = [];
    this.stream = null;
    this.roomId = null;
    this.state = 'enter';
    if (!localStorage.name) {
      localStorage.name = '';
    }
    this.name = localStorage.name;
    this.videoMuted = true;
    this.audioMuted = true;
  }

  getOnlinePeers() {
    return Object.values(this.peers).filter((peer) => peer && peer.ready());
  }

  setCallback(callback) {
    const cb = (message) => {
      if (message) {
        this.messages.push(message);
      }
      this.updateVideoQuality();
      callback(this);
    };
    this.callback = cb;
  }

  sendChat(message) {
    Object.values(this.peers).forEach((peer) => {
      peer.sendChat(message);
    });
    this.messages.push({ id: 0, message });
    this.callback();
  }

  sendFile(file, incrementFileProgress) {
    const incrementPeerProgress = (maxPeers) => (peerProgressValue) => {
      incrementFileProgress(peerProgressValue / maxPeers);
    };

    Object.values(this.peers).forEach((peer) => {
      peer.sendFile(
        file,
        incrementPeerProgress(Object.values(this.peers).length),
      );
    });
    this.messages.push({ id: 0, message: file.name, href: URL.createObjectURL(file) });
    this.callback();
  }

  async openUserMedia() {
    this.stream = blackSilence();
    initHark(this);
  }

  async enableAudio() {
    try {
      const tracks = this.stream.getAudioTracks();
      tracks.forEach((track) => {
        this.stream.removeTrack(track);
      });
      const stream = await navigator.mediaDevices.getUserMedia({ audio: callConfig.audio });
      const audioTrack = stream.getAudioTracks()[0];
      this.stream.addTrack(audioTrack);
      Object.keys(this.peers).forEach(async (id) => {
        const sender = this.peers[id].audioSender;
        await sender.replaceTrack(audioTrack);
        const channel = this.peers[id].statusChannel;
        if (channel && channel.readyState === 'open') {
          channel.send('audiounmute');
        }
      });
      this.audioMuted = false;
      initHark(this);
      this.callback();
    } catch (e) {
      await this.disableAudio();
      console.error(e);
      return e;
    }
    return null;
  }

  async enableVideo() {
    try {
      console.log(this);
      const tracks = this.stream.getVideoTracks();
      tracks.forEach((track) => {
        this.stream.removeTrack(track);
      });
      const stream = await navigator.mediaDevices.getUserMedia({ video: callConfig.video });
      console.log(stream);
      const videoTrack = stream.getVideoTracks()[0];
      console.log(videoTrack);
      this.stream.addTrack(videoTrack);
      Object.keys(this.peers).forEach(async (id) => {
        if (this.peers[id].sendVideo) {
          const sender = this.peers[id].videoSender;
          await sender.replaceTrack(videoTrack);
        }
        const channel = this.peers[id].statusChannel;
        if (channel && channel.readyState === 'open') {
          channel.send('videounmute');
        }
      });
      this.videoMuted = false;
      this.callback();
    } catch (e) {
      console.error(e);
      await this.disableVideo();
      return e;
    }
    return null;
  }

  async startShareScreen() {
    try {
      const tracks = this.stream.getVideoTracks();
      tracks.forEach((track) => {
        track.stop();
        this.stream.removeTrack(track);
      });
      const stream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: true });
      const videoTrack = stream.getVideoTracks()[0];
      const audioTrack = stream.getAudioTracks()[0];
      this.stream.addTrack(videoTrack);
      if (audioTrack) {
        this.stream.addTrack(audioTrack);
      } else {
        console.log('LLLLLL');
      }
      Object.keys(this.peers).forEach(async (id) => {
        if (this.peers[id].sendVideo) {
          const sender = this.peers[id].videoSender;
          await sender.replaceTrack(videoTrack);
          const channel = this.peers[id].statusChannel;
          if (channel && channel.readyState === 'open') {
            setTimeout(() => {
              channel.send('startsharescreen'); // hard code to deal with timing issue of rerender on receiver
            }, 1000);
          }
        }
      });
      this.shareScreen = true;
      this.callback();
    } catch (e) {
      console.error(e);
      return e;
    }
    return null;
  }

  async disableAudio() {
    const tracks = this.stream.getAudioTracks();
    tracks.forEach((track) => {
      track.stop();
      this.stream.removeTrack(track);
    });
    this.stream.addTrack(silence());
    const audioTrack = this.stream.getAudioTracks()[0];
    Object.keys(this.peers).forEach(async (id) => {
      const sender = this.peers[id].audioSender;
      await sender.replaceTrack(audioTrack);
      const channel = this.peers[id].statusChannel;
      if (channel && channel.readyState === 'open') {
        channel.send('audiomute');
      }
    });
    this.audioMuted = true;
    initHark(this);
    this.callback();
  }

  async disableVideo() {
    const tracks = this.stream.getVideoTracks();
    tracks.forEach((track) => {
      track.stop();
      this.stream.removeTrack(track);
    });
    this.stream.addTrack(black());
    const videoTrack = this.stream.getVideoTracks()[0];
    Object.keys(this.peers).forEach(async (id) => {
      const sender = this.peers[id].videoSender;
      await sender.replaceTrack(videoTrack);
      const channel = this.peers[id].statusChannel;
      if (channel && channel.readyState === 'open') {
        channel.send('videomute');
      }
    });
    this.videoMuted = true;
    this.shareScreen = false;
    this.callback();
  }

  updateVideoQuality() {
    const videoBitrate = VIDEO_BITRATE(Object.values(this.peers).length);
    // console.log(videoBitrate);

    Object.values(this.peers).forEach((peer) => {
      peer.updateVideoQuality(Math.min(peer.maxVideoBitrate, videoBitrate));
    });
  }

  stopTracks() {
    if (this.stream) {
      const tracks = this.stream.getTracks();
      tracks.forEach((track) => {
        track.stop();
        this.stream.removeTrack(track);
      });
      this.stream = null;
    }
  }

  reset() {
    this.state = 'enter';
    if (this.callback) {
      this.callback();
    }
  }
}
