import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FileIcon, SendIcon } from '../../Icons';

const styles = {
  chatInput: {
    borderTopWidth: '1',
    borderTopStyle: 'solid',
    borderTopColor: '#ddd',
    display: 'flex',
    alignItems: 'center',
  },
  inputStyle: {
    border: 'none',
    fontSize: '16px',
    outline: 'none',
    padding: '5px',
    width: '100%',
    flex: 1,
  },
  buttonStyle: {
    background: 'none',
    padding: 0,
    border: 'none',
    boxShadow: 'none',
    margin: '0.25rem',
    height: '100%',
  },
  progressCircleStyle: {
    transition: 'stroke-dashoffset 0.35s',
    transform: 'rotate(-90deg)',
    transformOrigin: '50% 50%',
  },
};

// interface ChatInputProps {
//   inputStyles: object;
//   inputPlaceholder: string;
// }

const ChatInput = (props) => {
  const { fileProgress, inputStyles, inputPlaceholder, onFileUpload, overlay } = props;
  return (
    <form style={styles.chatInput} className="py-1" onSubmit={props.onMessageSubmit}>
      {(fileProgress === 0) && (overlay
        ? (
          <OverlayTrigger
            placement="top"
            overlay={(
              <Tooltip>
                Upload a file
              </Tooltip>
            )}
          >
            <FileIcon style={styles.buttonStyle} role="button" fontSize="1.5rem" color="var(--primary)" onClick={onFileUpload} />
          </OverlayTrigger>
        )
        : <FileIcon style={styles.buttonStyle} role="button" fontSize="1.5rem" color="var(--primary)" onClick={onFileUpload} />)}
      {(fileProgress !== 0) && (overlay
        ? (
          <OverlayTrigger
            placement="top"
            overlay={(
              <Tooltip>
                {`Uploading ${Math.floor(fileProgress * 100)}%`}
              </Tooltip>
            )}
          >
            <div>
              <ProgressRing radius={17} stroke={3} progress={fileProgress} />
            </div>
          </OverlayTrigger>
        )
        : <ProgressRing radius={17} stroke={3} progress={fileProgress} />)}
      <input type="text" style={inputStyles || styles.inputStyle} placeholder={inputPlaceholder} />
      {overlay
        ? (
          <OverlayTrigger
            placement="top"
            overlay={(
              <Tooltip>
                Send message
              </Tooltip>
            )}
          >
            <Button style={styles.buttonStyle} type="submit">
              <SendIcon fontSize="1.5rem" color="var(--primary)" />
            </Button>
          </OverlayTrigger>
        )
        : (
          <Button style={styles.buttonStyle} type="submit">
            <SendIcon fontSize="1.5rem" color="var(--primary)" />
          </Button>
        )}
    </form>
  );
};

class ProgressRing extends React.Component {
  constructor(props) {
    super(props);
    const { radius, stroke } = this.props;

    this.normalizedRadius = radius - stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
  }

  render() {
    const { radius, stroke, progress } = this.props;
    const strokeDashoffset = this.circumference - progress * this.circumference;
    return (
      <svg height={radius * 2} width={radius * 2}>
        <circle
          stroke="var(--primary)"
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={`${this.circumference} ${this.circumference}`}
          style={{ strokeDashoffset }}
          r={this.normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    );
  }
}

export default ChatInput;
