import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Form, FormControl, Modal, Carousel,
} from 'react-bootstrap';
import { createRoom } from '../helpers/call';

import InfoSlide1 from '../assets/img/infoslide1.jpg';
import InfoSlide2 from '../assets/img/infoslide2.jpg';
import InfoSlide3 from '../assets/img/infoslide3.jpg';
import InfoSlide4 from '../assets/img/infoslide4.jpg';
import InfoSlide5 from '../assets/img/infoslide5.jpg';
import InfoSlide6 from '../assets/img/infoslide6.jpg';

function addScript(url) {
  const script = document.createElement('script');
  script.src = url;
  document.body.appendChild(script);
}

export default class Info extends Component {
  constructor(props) {
    super(props);
    this.handleCreateRoom = this.handleCreateRoom.bind(this);
    this.handleJoinRoom = this.handleJoinRoom.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalShow = this.handleModalShow.bind(this);
    this.state = {
      modalOpen: false,
      roomId: '',
      formValidated: false,
    };
  }

  componentDidMount() {
    addScript('../assets/js/new-age.min.js');
  }

  async handleCreateRoom() {
    const { goRoom } = this.props;
    const roomId = await createRoom();
    goRoom(roomId);
  }

  handleJoinRoom(e) {
    const { goRoom } = this.props;
    const { roomId } = this.state;
    e.preventDefault();
    if (roomId) {
      goRoom(roomId);
    } else {
      this.setState({ formValidated: true });
    }
  }

  handleModalClose() {
    this.setState({ modalOpen: false });
  }

  handleModalShow() {
    this.setState({ modalOpen: true });
  }

  render() {
    const { roomId, modalOpen, formValidated } = this.state;
    return (
      <>
        <Modal show={modalOpen} onHide={this.handleModalClose}>
          <Form noValidate onSubmit={this.handleJoinRoom}>
            <Modal.Header closeButton>
              <Modal.Title>Join Room</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Label>Enter Room ID:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Room ID"
                required
                value={roomId}
                isInvalid={!roomId && formValidated}
                onChange={(event) => this.setState({ roomId: event.target.value })}
              />
              <FormControl.Feedback type="invalid">
                Please enter a Room ID.
              </FormControl.Feedback>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleModalClose}>
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Join Room
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
          <div className="container">
            <a className="navbar-brand js-scroll-trigger" href="/">Pari</a>
            <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
              {'Menu '}
              <i className="fas fa-bars" />
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a className="nav-link" href="/">Home</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link js-scroll-trigger" href="#process">Process</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link js-scroll-trigger" href="#Call">Call</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://medium.com/pariorg">
                    Blog
                    {/* <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
                      <title>Medium</title>
                      <path fill="#2E3338" d="M0 0V24H24V0H0ZM19.9385 5.68615L18.6511 6.92062C18.5403 7.00554 18.4849 7.14462 18.5083 7.28246V16.3495C18.4849 16.4874 18.5403 16.6265 18.6511 16.7114L19.9089 17.9446V18.2154H13.5852V17.9446L14.8874 16.6806C15.0154 16.5526 15.0154 16.5157 15.0154 16.32V8.99077L11.3957 18.1858H10.9058L6.69046 8.99077V15.1532C6.656 15.4117 6.74215 15.6726 6.92431 15.8609L8.61785 17.9151V18.1858H3.81538V17.9151L5.50892 15.8609C5.68985 15.6726 5.77108 15.4105 5.72677 15.1532V8.02708C5.74769 7.82892 5.67138 7.63323 5.52369 7.50031L4.01846 5.68615V5.41538H8.69292L12.3065 13.3391L15.4818 5.41538H19.9385V5.68615Z" />
                    </svg> */}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <section className="safety" id="safety">
          <Carousel>
            <Carousel.Item>
              <img className="d-block w-100 vh-100" src={InfoSlide1} alt="First slide" />
              <Carousel.Caption>
                <h1>Pari's decentralized vision was created to end the data surveillance of video calls</h1>
                <br />
                <h4> Our not-for-profit model means we put privacy over profits</h4>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block w-100 vh-100" src={InfoSlide2} alt="Second slide" />
              <Carousel.Caption>
                <h1>Pari cannot monetize your call data or sell it to third parties</h1>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block w-100 vh-100" src={InfoSlide3} alt="Third slide" />
              <Carousel.Caption>
                <h1>Your conversations cannot be monitored by Pari or others</h1>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block w-100 vh-100" src={InfoSlide4} alt="Fourth slide" />
              <Carousel.Caption>
                <h1>Hackers cannot steal your info by attacking the Pari servers or using phishing campaigns</h1>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block w-100 vh-100" src={InfoSlide5} alt="Fifth slide" />
              <Carousel.Caption>
                <h1>Pari cannot be forced to turn over anything to governments because we collect no data</h1>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block w-100 vh-100" src={InfoSlide6} alt="Sixth slide" />
              <Carousel.Caption>
                <h1>
                  Email us if you have any questions about Pari
                </h1>
                <br />
                <div>
                  <a href="mailto:contact@paricall.org" className="btn btn-outline btn-xl js-scroll-trigger"> Contact Us </a>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>

        </section>

        <section className="process" id="process">
          <div className="container">
            <div className="section-heading text-center">
              <h2>Pari's Model Puts Users in Power </h2>
              <p className="text-muted">We Protect Your Privacy Unlike Traditional Video Calling Platforms</p>
              <hr />
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="mx-auto text-center pt-5 pb-3">
                  <h1>
                    <div className="colorsecondary font-weight-bold"> Pari </div>
                  </h1>
                </div>
                <div className="row pt-3 pb-3">
                  <div className="col-md-2">
                    <div className="mx-auto text-center">
                      <p> 1 </p>
                    </div>
                  </div>
                  <div className="col-md-10 mx-auto">
                    <div className="mx-auto">
                      <p>
                        You and your friend’s devices exchange IP addresses through the Pari server.
                        <b> This is the only centralized process. </b>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row pt-3 pb-3">
                  <div className="col-md-2">
                    <div className="mx-auto text-center">
                      <p> 2 </p>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="mx-auto">
                      <p>
                        Your call data is encrypted and sent from your device browser to the national and/or international Internet Service Provider (ISP).
                        <b> This is how all data reaches the worldwide Internet. </b>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row pt-3 pb-3">
                  <div className="col-md-2">
                    <div className="mx-auto text-center">
                      <p> 3 </p>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="mx-auto">
                      <p>
                        Your call is routed directly to your friend’s regional and local ISP through the Internet.
                        <b> This means we can never access or track the call data. </b>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row pt-3 pb-3">
                  <div className="col-md-2">
                    <div className="mx-auto text-center">
                      <p> 4 </p>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="mx-auto">
                      <p>
                        Finally, your call is decrypted by your friend’s device.
                        <b> We DO NOT collect any call data and do not profit from your call. </b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mx-auto text-center pt-5 pb-3">
                  <h1>
                    <div className="font-weight-bolder">
                      Traditional Video Calling
                    </div>
                  </h1>
                </div>
                <div className="row pt-3 pb-3">
                  <div className="col-md-2">
                    <div className="mx-auto text-center">
                      <p> 1 </p>
                    </div>
                  </div>
                  <div className="col-md-10 my-auto">
                    <div className=" mx-auto">
                      <p>
                        You and your friend create an account with the Video Calling Platform to exchange and save contact addresses.
                        <b> This means your account can be hacked by a malicious actor or your data can be stolen by the owner of the central server. </b>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row pt-3 pb-3">
                  <div className="col-md-2">
                    <div className="mx-auto text-center">
                      <p> 2 </p>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="mx-auto">
                      <p> Your call data is encrypted and sent from your Device Application to the national  Internet Service Provider (ISP).</p>
                    </div>
                  </div>
                </div>
                <div className="row pt-3 pb-3">
                  <div className="col-md-2">
                    <div className="mx-auto text-center">
                      <p> 3 </p>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="mx-auto">
                      <p>
                        Your call is routed to the Video Calling Platform’s central servers where it is often decrypted.
                        <b> Here, the Company gains complete control over your data and can collect call information without your explicit permission. </b>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row pt-3 pb-3">
                  <div className="col-md-2">
                    <div className="mx-auto text-center">
                      <p> 4 </p>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="mx-auto">
                      <p> Your call is routed from the Company server to the national and/or international ISP to eventually reach your friend’s regional and local ISP.</p>
                    </div>
                  </div>
                </div>
                <div className="row pt-3 pb-3">
                  <div className="col-md-2">
                    <div className="mx-auto text-center">
                      <p> 5 </p>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="mx-auto">
                      <p> Finally, your friend’s Device Application receives and decrypts the call.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <header className="masthead" id="Call">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-md-12 my-auto">
                <div className="mx-auto text-center">
                  <h1 className="mb-2">Try Pari Now </h1>
                  <h3> And Experience a Better Video Call</h3>
                  <br />
                  <div className="btn btn-outline btn-xl m-2" onClick={this.handleCreateRoom}>Start a Call</div>
                  <div className="btn btn-outline btn-xl m-2" onClick={this.handleModalShow}>Join a Call</div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <footer>
          <div className="container">
            <p>&copy; Pari. All Rights Reserved.</p>
            <ul className="list-inline">
              <li className="list-inline-item">
                <a href="/" onClick={this.handleCreateRoom}>Home</a>
              </li>
              |
              <li className="list-inline-item">
                <a className="js-scroll-trigger" href="#process">Process</a>
              </li>
              |
              <li className="list-inline-item">
                <a href="mailto:contact@paricall.org">Contact</a>
              </li>
              |
              <li className="list-inline-item">
                <a href="https://medium.com/pariorg">Blog</a>
              </li>
            </ul>
          </div>
        </footer>
      </>
    );
  }
}

Info.defaultProps = {
  goRoom: () => {},
};

Info.propTypes = {
  goRoom: PropTypes.func,
};
