import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Call from './Call';
import Enter from './Enter';
import Caller from '../helpers/callfirebase';

export default class Room extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caller: new Caller(),
    };
  }

  componentDidMount() {
    const { caller } = this.state;
    caller.setCallback(
      (callerObj) => {
        this.setState({ caller: callerObj });
      },
    );
  }

  render() {
    const { caller } = this.state;
    const { roomId, goHome } = this.props;
    switch (caller.state) {
      case 'call':
        return (
          <Call
            caller={caller}
            roomId={roomId}
            goHome={goHome}
          />
        );
      case 'enter':
        return (
          <Enter
            caller={caller}
            roomId={roomId}
            goHome={goHome}
          />
        );
      default:
        console.err('unknown room state');
        return null;
    }
  }
}

Room.defaultProps = {
  roomId: '',
  goHome: () => {},
};

Room.propTypes = {
  roomId: PropTypes.string,
  goHome: PropTypes.func,
};
