import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Container, Form, Modal, Row, Col,
} from 'react-bootstrap';
import FormControl from 'react-bootstrap/FormControl';
import { detect } from 'detect-browser';
import Video, { VideosWrap } from '../components/Video';
import CopyURL from '../components/CopyURL';
import Caller from '../helpers/call';

const browser = detect();

// handle the case where we don't detect the browser
if (browser) {
  console.log(browser.name);
  console.log(browser.version);
  console.log(browser.os);
}

const APP_NAME = 'Pari';
const APP_URL = 'paricall.org';

const ERROR_MSG = (input, error) => {
  switch (error) {
    case 'NotAllowedError':
      return `You did not give us permission to access your ${input}. Please allow ${input} access, refresh the page, and try again.`;
    case 'NotFoundError':
      return `Your device does not have a ${input}. Please try using another device if you still wish to use your ${input}.`;
    default:
      return `We could not access your device's ${input}. Please try using another device if you still wish to use your ${input}.`;
  }
};

export default class Enter extends Component {
  constructor(props) {
    super(props);
    this.handleJoinRoom = this.handleJoinRoom.bind(this);
    this.handleLeaveRoom = this.handleLeaveRoom.bind(this);
    this.handleErrorModalClose = this.handleErrorModalClose.bind(this);
    this.handleModalShow = this.handleModalShow.bind(this);
    this.enableVideo = this.enableVideo.bind(this);
    this.toggleVideo = this.toggleVideo.bind(this);
    this.enableAudio = this.enableAudio.bind(this);
    this.toggleAudio = this.toggleAudio.bind(this);
    this.state = {
      name: localStorage.name || '',
    };
    this.videoRef = React.createRef();
  }

  async componentDidMount() {
    const { caller } = this.props;
    if (browser.os === 'iOS' && browser.name !== 'ios') {
      this.setState({ errorModal: { title: 'Sorry, your browser is not supported.', msg: `The only browser that supports ${APP_NAME} on Apple devices is Safari. Please copy the URL and open it Safari instead.` } });
      return;
    }
    await caller.openUserMedia();
  }

  componentDidUpdate() {
  }

  handleLeaveRoom() {
    const { caller, goHome } = this.props;
    caller.stopTracks();
    goHome(false);
  }

  async handleJoinRoom(e) {
    const { caller, roomId } = this.props;
    const { name } = this.state;
    e.preventDefault();
    if (name) {
      this.setState({ loading: 'join' });
      caller.hangUp();
      await caller.joinRoomById(roomId, name);
    }
  }

  handleErrorModalClose() {
    this.setState({ errorModal: undefined });
  }

  async handleModalShow() {
    const { caller } = this.props;
    if (browser.os === 'iOS' && browser.name !== 'ios') {
      this.setState({ errorModal: { title: 'Sorry, your browser is not supported.', msg: `The only browser that supports ${APP_NAME} on Apple devices is Safari. Please copy the URL and open it Safari instead.` } });
      return;
    }
    await caller.openUserMedia();
  }

  async enableAudio() {
    const { caller } = this.props;
    const e = await caller.enableAudio();
    if (e) {
      console.log(e.name);
      this.setState({ errorModal: { title: "Couldn't access microphone", msg: ERROR_MSG('microphone', e.name) } });
    }
    return e;
  }

  async toggleAudio() {
    const { caller } = this.props;
    if (caller.audioMuted) {
      await this.enableAudio();
    } else {
      await caller.disableAudio();
    }
  }

  async enableVideo() {
    const { caller } = this.props;
    const e = await caller.enableVideo();
    if (e) {
      console.log(e.name);
      this.setState({ errorModal: { title: "Couldn't access camera", msg: ERROR_MSG('camera', e.name) } });
    }
    return e;
  }

  async toggleVideo() {
    const { caller } = this.props;
    if (caller.videoMuted) {
      await this.enableVideo();
    } else {
      await caller.disableVideo();
    }
  }

  renderDesktop() {
    const {
      errorModal,
      loading,
      name,
    } = this.state;
    const {
      caller,
      roomId,
    } = this.props;
    return (
      <div className="vw-100 vh-100 d-flex flex-column">
        <nav className="navbar navbar-expand-lg navbar-light masthead" id="mainNav">
          <div className="container">
            <a className="navbar-brand js-scroll-trigger text-white" href="/">{APP_NAME}</a>
          </div>
        </nav>
        <Container bg="light" className="flex-grow-1 flex-shrink-1 h-25 d-flex">
          <Modal show={errorModal !== undefined} onHide={this.handleErrorModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                {errorModal && errorModal.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="font-weight-bold">
              {errorModal && errorModal.msg}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleErrorModalClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Row className="justify-content-md-center flex-grow-1">
            <Col sm="auto" className="h-100 overflow-auto pb-2 d-flex flex-column align-items-center justify-content-center">
              <h4 className="mb-2" style={{ textAlign: 'center' }}>
                {'Send Link to Invite Others: '}
                <br />
                <b>
                  {`${APP_URL}/room/${roomId}`}
                </b>
              </h4>
              <CopyURL roomId={roomId} placement="right" />
              <Form noValidate onSubmit={this.handleJoinRoom} className="pt-3" style={{ width: '21rem' }}>
                <Form.Group>
                  <Form.Label>People will see you as:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="eg. Jane Doe"
                    isInvalid={!name}
                    value={name}
                    onChange={(event) => this.setState({ name: event.target.value })}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Join room with:</Form.Label>
                  <Form.Check
                    type="checkbox"
                    id="enableMicrophone"
                    label="Microphone On"
                    checked={!caller.audioMuted}
                    onChange={this.toggleAudio}
                  />
                  <Form.Check
                    type="checkbox"
                    id="enableCamera"
                    label="Camera On"
                    checked={!caller.videoMuted}
                    onChange={this.toggleVideo}
                  />
                  <Form.Control
                    style={{ display: 'none' }}
                    isInvalid={!name}
                    isValid={name}
                  />
                  <FormControl.Feedback type="invalid">
                    Please enter your name.
                  </FormControl.Feedback>
                  <FormControl.Feedback type="valid">
                    Ready to join meeting!
                  </FormControl.Feedback>
                </Form.Group>
                <div className="mb-5 d-flex justify-content-around">
                  <Button
                    className="flex-grow-1 mr-2"
                    variant="outline-danger"
                    onClick={this.handleLeaveRoom}
                  >
                    Back to Home
                  </Button>
                  <Button
                    className="flex-grow-1"
                    variant="primary"
                    type="submit"
                    disabled={loading === 'join'}
                  >
                    {loading === 'join' ? 'Joining Meeting...' : 'Join Meeting'}
                  </Button>
                </div>
              </Form>
            </Col>
            <Col className="h-100 overflow-auto pb-2 d-flex flex-column align-items-center">
              <VideosWrap>
                <Video
                  videoWidth="90%"
                  name={name}
                  muted
                  flipped
                  peer={caller}
                />
              </VideosWrap>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  renderMobile() {
    const {
      errorModal,
      loading,
      name,
    } = this.state;
    const {
      caller,
      roomId,
    } = this.props;
    return (
      <>
        <Container bg="light" fluid>
          <Modal show={errorModal !== undefined} onHide={this.handleErrorModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                {errorModal && errorModal.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="font-weight-bold">
              {errorModal && errorModal.msg}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleErrorModalClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Row className="justify-content-md-center h-100">
            <Col xs={12} className="h-100 overflow-hidden d-flex flex-column align-items-center">
              <div className="navbar navbar-expand-lg navbar-light navbar-shrink p-0 w-100" id="sideNav">
                <div className="container">
                  <a className="navbar-brand js-scroll-trigger p-0" href="/">{APP_NAME}</a>
                </div>
              </div>
              <h5 className="mb-1" style={{ textAlign: 'center' }}>
                {'Send Link to Invite Others: '}
                <br />
                <b>
                  {`${APP_URL}/room/${roomId}`}
                </b>
              </h5>
              <CopyURL roomId={roomId} className="mb-1" placement="bottom" size="sm" />
              <Form noValidate onSubmit={this.handleJoinRoom} className="pt-1" style={{ width: '21rem', maxWidth: '100%' }}>
                <Form.Group className="mb-1">
                  <Form.Label className="mb-1">People will see you as:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="eg. Jane Doe"
                    isInvalid={!name}
                    value={name}
                    onChange={(event) => this.setState({ name: event.target.value })}
                  />
                </Form.Group>
                <Form.Group className="mb-1">
                  <Form.Label className="mb-1">Join room with:</Form.Label>
                  <Form.Check
                    type="checkbox"
                    id="enableMicrophone"
                    label="Microphone On"
                    checked={!caller.audioMuted}
                    onChange={this.toggleAudio}
                  />
                  <Form.Check
                    type="checkbox"
                    id="enableCamera"
                    label="Camera On"
                    checked={!caller.videoMuted}
                    onChange={this.toggleVideo}
                  />
                  <Form.Control
                    style={{ display: 'none' }}
                    isInvalid={!name}
                    isValid={name}
                  />
                  <FormControl.Feedback type="invalid">
                    Please enter your name.
                  </FormControl.Feedback>
                  <FormControl.Feedback type="valid">
                    Ready to join meeting!
                  </FormControl.Feedback>
                </Form.Group>
                <VideosWrap>
                  <Video
                    videoWidth="80%"
                    name={name}
                    muted
                    flipped
                    peer={caller}
                    isTalking={false}
                  />
                </VideosWrap>
                <div className="mt-2 d-flex justify-content-around">
                  <Button
                    size="sm"
                    className="flex-grow-1 mr-2"
                    variant="outline-danger"
                    onClick={this.handleLeaveRoom}
                  >
                    Back to Home
                  </Button>
                  <Button
                    size="sm"
                    className="flex-grow-1"
                    variant="primary"
                    type="submit"
                    disabled={loading === 'join'}
                  >
                    {loading === 'join' ? 'Joining Meeting...' : 'Join Meeting'}
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  render() {
    return window.screen.width < 600 || window.screen.height < 600
      ? this.renderMobile() : this.renderDesktop();
  }
}

Enter.defaultProps = {
  caller: null,
  roomId: '',
  goHome: () => {},
};

Enter.propTypes = {
  caller: PropTypes.instanceOf(Caller),
  roomId: PropTypes.string,
  goHome: PropTypes.func,
};
