import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FaMicrophone, FaMicrophoneSlash, FaSlash } from 'react-icons/fa';
import {
  MdCallEnd, MdFullscreen, MdScreenShare, MdExpandLess, MdExpandMore,
} from 'react-icons/md';
import { BsCameraVideo, BsFillChatFill } from 'react-icons/bs';
import { RiPushpinLine } from 'react-icons/ri';
import { AiOutlineSend, AiOutlineFileText, AiFillAppstore } from 'react-icons/ai';
import { HiSpeakerphone } from 'react-icons/hi';

const baseInputStyles = css`
  display: block;
  ${({ color }) => color && `color: #${color};`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize};`}
  ${({ position }) => position && `position: ${position};`}
  ${({ left }) => left && `left: ${left};`}
  ${({ top }) => top && `top: ${top};`}
`;

export const MuteIcon = styled(FaMicrophoneSlash)`
  ${baseInputStyles}
`;

export const TalkingIcon = styled(FaMicrophone)`
  ${baseInputStyles}
`;

export const SlashIcon = styled(FaSlash)`
  ${baseInputStyles}
`;

export const CameraIcon = styled(BsCameraVideo)`
  ${baseInputStyles}
`;

function CameraOffIcon({ fontSize, color }) {
  return (
    <div style={{ width: fontSize, height: fontSize, position: 'relative' }}>
      <SlashIcon color={color} fontSize={fontSize} position="absolute" top="0px" left="0px" />
      <CameraIcon color={color} fontSize={`calc(0.875 * ${fontSize})`} position="absolute" top={`calc(0.07 * ${fontSize})`} left={`calc(0.07 * ${fontSize})`} />
    </div>
  );
}

CameraOffIcon.defaultProps = {
  fontSize: '1em',
  color: undefined,
};

CameraOffIcon.propTypes = {
  fontSize: PropTypes.string,
  color: PropTypes.string,
};

export { CameraOffIcon };

export const FullScreenIcon = styled(MdFullscreen)`
  ${baseInputStyles}
`;

export const HangUpIcon = styled(MdCallEnd)`
  ${baseInputStyles}
`;

export const ShareScreenIcon = styled(MdScreenShare)`
  ${baseInputStyles}
`;

export const ChatIcon = styled(BsFillChatFill)`
  ${baseInputStyles}
`;

export const PinIcon = styled(RiPushpinLine)`
  ${baseInputStyles}
`;

export const SendIcon = styled(AiOutlineSend)`
  ${baseInputStyles}
`;

export const FileIcon = styled(AiOutlineFileText)`
  ${baseInputStyles}
`;

export const MoreIcon = styled(MdExpandMore)`
  ${baseInputStyles}
`;

export const LessIcon = styled(MdExpandLess)`
  ${baseInputStyles}
`;

export const PanelViewIcon = styled(AiFillAppstore)`
  ${baseInputStyles}
`;

export const SpeakerViewIcon = styled(HiSpeakerphone)`
  ${baseInputStyles}
`;
