import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Badge, Button, Container, ListGroup, Modal, OverlayTrigger, Tooltip, Row, Col, Nav, Tab, Collapse,
} from 'react-bootstrap';
import Switch from 'react-switch';
import ResizeObserver from 'resize-observer-polyfill';
import { detect } from 'detect-browser';
import Video, { VideosWrap, VideosOverflow } from '../components/Video';
import CopyURL from '../components/CopyURL';
import Chat from '../components/Chat';
import {
  MuteIcon,
  TalkingIcon,
  CameraOffIcon,
  CameraIcon,
  HangUpIcon,
  ShareScreenIcon,
  ChatIcon,
  PinIcon,
  MoreIcon,
  LessIcon,
  FileIcon,
  PanelViewIcon,
  SpeakerViewIcon,
} from '../components/Icons';
import Caller from '../helpers/call';

const browser = detect();

// handle the case where we don't detect the browser
if (browser) {
  console.log(browser.name);
  console.log(browser.version);
  console.log(browser.os);
}

const APP_NAME = 'Pari';
const APP_URL = 'paricall.org';

const ERROR_MSG = (input, error) => {
  switch (error) {
    case 'NotAllowedError':
      return `You did not give us permission to access your ${input}. Please allow ${input} access, refresh the page, and try again.`;
    case 'NotFoundError':
      return `Your device does not have a ${input}. Please try using another device if you still wish to use your ${input}.`;
    default:
      return `We could not access your device's ${input}. Please try using another device if you still wish to use your ${input}.`;
  }
};

export default class Call extends Component {
  constructor(props) {
    super(props);
    this.handleExitCall = this.handleExitCall.bind(this);
    this.handleErrorModalClose = this.handleErrorModalClose.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.enableVideo = this.enableVideo.bind(this);
    this.toggleVideo = this.toggleVideo.bind(this);
    this.enableAudio = this.enableAudio.bind(this);
    this.toggleAudio = this.toggleAudio.bind(this);
    this.startShareScreen = this.startShareScreen.bind(this);
    this.endShareScreen = this.endShareScreen.bind(this);
    this.toggleShareScreen = this.toggleShareScreen.bind(this);
    this.handleVideoPin = this.handleVideoPin.bind(this);
    this.handleChangeView = this.handleChangeView.bind(this)
    this.handleTalking = this.handleTalking.bind(this);
    this.handleFileButton = this.handleFileButton.bind(this);
    this.renderMobile = this.renderMobile.bind(this);
    this.renderDesktop = this.renderDesktop.bind(this);
    this.state = {
      pin: 0,
      tabkey: 'participants',
      tabOn: true,
      talking: 0,
      speakerView: true,
    };
    this.videoRef = React.createRef();
    this.chatRef = React.createRef();
    this.readMessages = 0;
  }

  async componentDidMount() {
    const { caller } = this.props;
    window.addEventListener('blur', this.handleBlur);
    const handleResize = () => {
      if (this.videoRef.current) {
        this.setState({
          videosHeight: this.videoRef.current.clientHeight,
          videosWidth: this.videoRef.current.clientWidth - 30,
        });
      }
    };
    handleResize();
    if (this.videoRef.current) {
      new ResizeObserver(handleResize).observe(this.videoRef.current);
    }
    // Enable navigation prompt
    window.onbeforeunload = () => {
      caller.hangUp();
      return undefined;
    };
  }

  componentDidUpdate() {
    const { caller } = this.props;
    const { videoWidth } = this.state;
    if (!window.document.hasFocus()) {
      this.handleBlur();
    }

    const currentOnlineMembers = Object.values(caller.peers).filter((peer) => peer && peer.ready());
    const numOtherParticipants = currentOnlineMembers.length;
    const newVideoWidth = `${this.computeVideoWidth(numOtherParticipants)}px`;
    if (newVideoWidth !== videoWidth) {
      this.setState({ videoWidth: newVideoWidth });
    }
  }

  async componentWillUnmount() {
    const { caller } = this.props;
    caller.hangUp();
    caller.stopTracks();
    window.removeEventListener('blur', this.handleBlur);
    window.onbeforeunload = null;
  }

  handleBlur() {
    const { caller } = this.props;
    clearTimeout(this.autoLeave);
    const currentOnlineMembers = Object.values(caller.peers).filter((peer) => peer && peer.ready());
    if (currentOnlineMembers.length === 0) {
      this.autoLeave = setTimeout(caller.reset, 300000);
    }
  }

  handleExitCall() {
    const { caller, roomId, goHome } = this.props;
    this.setState({ loading: 'leave' });
    caller.hangUp();
    caller.stopTracks();
    this.setState({ loading: null, chatOpen: false });
    goHome(roomId);
  }

  handleErrorModalClose() {
    this.setState({ errorModal: undefined });
  }

  async enableAudio() {
    const { caller } = this.props;
    const e = await caller.enableAudio();
    if (e) {
      console.log(e.name);
      this.setState({ errorModal: { title: "Couldn't access microphone", msg: ERROR_MSG('microphone', e.name) } });
    }
    return e;
  }

  async toggleAudio() {
    const { caller } = this.props;
    if (caller.audioMuted) {
      await this.enableAudio();
    } else {
      await caller.disableAudio();
    }
  }

  async enableVideo() {
    const { caller } = this.props;
    const e = await caller.enableVideo();
    if (e) {
      console.log(e.name);
      this.setState({ errorModal: { title: "Couldn't access camera", msg: ERROR_MSG('camera', e.name) } });
    }
    return e;
  }

  async toggleVideo() {
    const { caller } = this.props;
    if (caller.videoMuted) {
      await this.enableVideo();
    } else {
      await caller.disableVideo();
    }
  }

  async startShareScreen() {
    const { caller } = this.props;
    const e = await caller.startShareScreen();
    if (e) {
      console.log(e.toString());
      if (e.toString() !== 'NotAllowedError: Permission denied') {
        this.setState({ errorModal: { title: "Couldn't share screen", msg: e.toString() } });
      }
      await this.endShareScreen();
    } else {
      caller.stream.getVideoTracks()[0].onended = this.endShareScreen;
    }
    return e;
  }

  async endShareScreen() {
    const { caller } = this.props;
    if (caller.videoMuted) {
      await caller.disableVideo();
    } else {
      await caller.disableVideo();
      await this.enableVideo();
    }
  }

  async toggleShareScreen() {
    const { caller } = this.props;
    if (!caller.shareScreen) {
      await this.startShareScreen();
    } else {
      await this.endShareScreen();
    }
  }

  // hacky way of determining video size for panel view
  computeVideoWidth(numVid) {
    if (this.videoRef.current) {
      const height = this.videoRef.current.clientHeight;
      const width = this.videoRef.current.clientWidth - 10;
      let hi = Math.min(width, height / 0.75); let lo = 0; let mid;
      while (hi > lo) {
        mid = Math.floor((hi + lo) / 2);
        const maxrows = Math.floor(height / (mid * 0.75 + 20));
        const maxcols = Math.floor(width / (mid + 20));
        if (maxrows * maxcols >= numVid) { // successfully fit
          lo = mid + 1;
        } else { // failed to fit
          hi = mid;
        }
      }
      if (hi > height * 1.2) {
        hi = height * 1.2;
      }
      return hi;
    }
    return null;
  }

  handleVideoPin(peerId) {
    const { pin } = this.state;
    if (pin === peerId) {
      this.setState({ pin: 0 });
    } else {
      this.setState({ pin: peerId });
    }
  }

  handleChangeView(checked) {
    this.setState({speakerView: checked})
  }

  handleTalking(peerId) {
    const self = this;
    return () => {
      if (self.state.speakerView && peerId !== self.state.talking) {
        self.setState({ talking: peerId });
      }
    };
  }

  handleFileButton() {
    this.setState({ chatOpen: true });
    this.chatRef.current.handleModalShow();
  }

  renderMobile() {
    const {
      tabkey,
      pin,
      errorModal,
      tabOn,
      loading,
      talking,
      speakerView,
    } = this.state;
    const {
      caller,
      roomId,
    } = this.props;
    const currentOnlineMembers = Object.values(caller.peers).filter((peer) => peer && peer.ready());
    const numOtherParticipants = currentOnlineMembers.length;
    if (tabkey === 'chat') {
      this.readMessages = caller.messages.length;
    }
    const unreadMessages = caller.messages.length - this.readMessages;

    let focusedPeer = currentOnlineMembers.reduce((max, next) => {
      if (next.peerId === talking && speakerView && !max.peerId) {
        return next;
      }
      if (next.peerId === pin && !max.shareScreen) {
        return next;
      }
      if (next.shareScreen && (!max.shareScreen || max.shareScreen > next.shareScreen)) {
        return next;
      }
      return max;
    }, {});
    if (!focusedPeer.peerId && currentOnlineMembers.length) {
      focusedPeer = currentOnlineMembers[0];
    }

    currentOnlineMembers.forEach((peer) => {
      if (peer === focusedPeer) {
        if (!peer.receiveVideo && peer.statusChannel && peer.statusChannel.readyState === 'open') {
          peer.statusChannel.send('sendvideo');
          peer.receiveVideo = true;
        }
      } else if (peer.receiveVideo && peer.statusChannel && peer.statusChannel.readyState === 'open') {
        peer.statusChannel.send('stopvideo');
        peer.receiveVideo = false;
      }
      // this.oldOnlineMembers[peer.peerId] = peer;
    });
    const viewHeight = `${document.documentElement ? document.documentElement.clientHeight : window.innerHeight}px`;
    return (
      <>
        <Container bg="light" fluid className="d-flex flex-column" style={{ height: viewHeight }}>
          <Modal show={errorModal !== undefined} onHide={this.handleErrorModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                {errorModal && errorModal.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="font-weight-bold">
              {errorModal && errorModal.msg}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleErrorModalClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Row className="justify-content-md-center">
            <Col className="overflow-hidden pb-2 px-0 d-flex flex-column w-100 align-items-center">
              <div style={currentOnlineMembers.length ? {
                position: 'absolute', top: '10px', right: '10px', width: '25%', zIndex: 1,
              } : { width: '100%' }}
              >
                <Video
                  videoWidth={tabOn || currentOnlineMembers.length ? '100%' : '2000px'}
                  videoHeight={viewHeight}
                  name="You"
                  muted
                  flipped={!caller.shareScreen}
                  peer={caller}
                  mobile
                  noIcons
                  noName={currentOnlineMembers.length}
                />
              </div>
              {currentOnlineMembers.map((peer) => (
                <Video
                  videoWidth={tabOn ? '100%' : '2000px'}
                  videoHeight={viewHeight}
                  key={peer.peerId}
                  peer={peer}
                  hidden={focusedPeer !== peer}
                  mobile
                  onTalk={this.handleTalking(peer.peerId)}
                />
              ))}
              <div className="mb-2 d-flex w-100 justify-content-center" style={{ position: 'absolute', bottom: 5 }}>
                {/* Invisible button for hacky way of aligning buttons */}
                <Button className="ml-2 mb-1 mr-auto p-2 rounded-circle invisible">
                  <MoreIcon fontSize="1.5em" />
                </Button>
                <Button
                  className="mr-2 mb-1 p-2 rounded-circle"
                  variant={caller.audioMuted ? 'primary' : 'outline-primary'}
                  style={caller.audioMuted ? {} : { backgroundColor: 'transparent', color: 'var(--primary)' }}
                  onClick={this.toggleAudio}
                >
                  {caller.audioMuted ? <MuteIcon fontSize="1.5em" /> : <TalkingIcon fontSize="1.5em" />}
                </Button>
                <Button
                  className="mr-2 mb-1 p-2 rounded-circle"
                  variant="danger"
                  onClick={this.handleExitCall}
                  disabled={loading === 'leave'}
                >
                  <HangUpIcon fontSize="1.5em" />
                </Button>
                <Button
                  className="mr-auto mb-1 p-2 rounded-circle"
                  variant={caller.videoMuted ? 'primary' : 'outline-primary'}
                  style={caller.videoMuted ? {} : { backgroundColor: 'transparent', color: 'var(--primary)' }}
                  onClick={this.toggleVideo}
                >
                  {caller.videoMuted ? <CameraOffIcon fontSize="1.5em" /> : <CameraIcon fontSize="1.5em" />}
                </Button>
                <Button
                  className="mr-2 mb-1 p-2 rounded-circle"
                  variant="light"
                  onClick={() => this.setState({ tabOn: !tabOn })}
                >
                  {tabOn ? <MoreIcon fontSize="1.5em" /> : <LessIcon fontSize="1.5em" />}
                </Button>
              </div>
            </Col>
          </Row>
          <Collapse in={tabOn}>
            <Row className="justify-content-md-center flex-grow-1 overflow-hidden flex-column">
              <Col className="h-100 pb-2 d-flex flex-column">
                <Tab.Container activeKey={tabkey} onSelect={(k) => this.setState({ tabkey: k })}>
                  <Nav className="nav-tabs nav-fill shadow-none">
                    <Nav.Item>
                      <Nav.Link eventKey="participants">Participants</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="chat"
                        // onClick={() => this.setState({ readMessages: caller.messages.length })}
                      >
                        {'Chat '}
                        {unreadMessages > 0 && tabkey !== 'chat' && <Badge variant="danger">{unreadMessages}</Badge>}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="info">Invite</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content className="flex-grow-1 overflow-hidden">
                    <Tab.Pane eventKey="participants" style={{ height: `calc(${viewHeight} - 75vw - 50px)` }}>
                      <h3 className="pt-2">
                        {`Participants (${numOtherParticipants + 1})`}
                      </h3>
                      <div className="overflow-hidden flex-fill w-100">
                        <ListGroup variant="flush" className="overflow-auto h-100">
                          <ListGroup.Item as="h5" className="d-flex flex-row align-items-center p-1 m-0">
                            <div className="mr-auto">
                              {`${caller.name} (You)`}
                            </div>
                            {caller.audioMuted ? <MuteIcon color="red" fontSize="1.25em" /> : <TalkingIcon color="gray" fontSize="1.25em" />}
                            {caller.videoMuted ? <CameraOffIcon color="red" fontSize="1.25em" /> : <CameraIcon color="gray" fontSize="1.25em" />}
                          </ListGroup.Item>
                          {currentOnlineMembers.map((peer) => (
                            <ListGroup.Item as="h5" key={peer.peerId} className="d-flex flex-row align-items-center p-1 m-0" onClick={() => this.handleVideoPin(peer.peerId)}>
                              <div className="mr-auto">{peer.name}</div>
                              {pin === peer.peerId && <PinIcon color="blue" fontSize="1.25em" />}
                              {peer.audioMuted ? <MuteIcon color="red" fontSize="1.25em" /> : <TalkingIcon color="gray" fontSize="1.25em" />}
                              {peer.videoMuted ? <CameraOffIcon color="red" fontSize="1.25em" /> : <CameraIcon color="gray" fontSize="1.25em" />}
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="chat" style={{ height: `calc(${viewHeight} - 75vw - 50px)` }}>
                      <Chat show caller={caller} maxHeight="500" mobile />
                    </Tab.Pane>
                    <Tab.Pane eventKey="info" className="pt-2" style={{ height: `calc(${viewHeight} - 75vw - 50px)` }}>
                      <div className="navbar-brand">{APP_NAME}</div>
                      <div className="m-2" style={{ textAlign: 'center' }}>
                        Send the join link to invite others:
                        <br />
                        <b>
                          {`${APP_URL}/room/${roomId}`}
                        </b>
                      </div>
                      <CopyURL className="w-100 mb-2 px-0" roomId={roomId} placement="bottom" size="sm" />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Col>
            </Row>
          </Collapse>
        </Container>
      </>
    );
  }

  renderDesktop() {
    const {
      pin,
      errorModal,
      loading,
      chatOpen,
      videosWidth,
      videosHeight,
      videoWidth,
      talking,
      speakerView,
    } = this.state;
    const {
      caller,
      roomId,
    } = this.props;
    const currentOnlineMembers = Object.values(caller.peers).filter((peer) => peer && peer.ready());
    const numOtherParticipants = currentOnlineMembers.length;
    if (chatOpen) {
      this.readMessages = caller.messages.length;
    }
    const unreadMessages = caller.messages.length - this.readMessages;
    const focusedPeer = currentOnlineMembers.reduce((max, next) => {
      if (next.peerId === talking && speakerView && !max.peerId) {
        return next;
      }
      if (next.peerId === pin && !max.shareScreen) {
        return next;
      }
      if (next.shareScreen && (!max.shareScreen || max.shareScreen > next.shareScreen)) {
        return next;
      }
      return max;
    }, {});
    return (
      <div className="vw-100 vh-100 d-flex flex-column">
        <Container fluid className="flex-grow-1 flex-shrink-1 h-25 overflow-hidden">
          <Modal show={errorModal !== undefined} onHide={this.handleErrorModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                {errorModal && errorModal.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="font-weight-bold">
              {errorModal && errorModal.msg}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleErrorModalClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Row className="justify-content-md-center h-100">
            <Col sm="auto" className="h-100 pb-2 d-flex flex-column align-items-center overflow-hidden" style={{ width: 280, borderRight: '1px solid rgba(0, 0, 0, 0.3)' }}>
              <nav className="navbar-shrink" id="sideNav">
                <div className="navbar-brand m-0">{APP_NAME}</div>
              </nav>
              <CopyURL className="w-100 mb-1 px-0" roomId={roomId} placement="right" />
              <div className="mb-1" style={{ textAlign: 'center' }}>
                {`${APP_URL}/room/${roomId}`}
              </div>
              <Video videoWidth="250px" id="localVideo" name="You" muted flipped={!caller.shareScreen} peer={caller} />
              <div className="py-2 d-flex w-100" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.3)' }}>
                <OverlayTrigger
                  placement="bottom"
                  overlay={(
                    <Tooltip>
                      {caller.audioMuted ? 'Unmute' : 'Mute'}
                    </Tooltip>
                  )}
                >
                  <Button
                    className="mr-2 p-2 rounded-circle"
                    variant={caller.audioMuted ? 'primary' : 'outline-primary'}
                    size="sm"
                    onClick={this.toggleAudio}
                  >
                    {caller.audioMuted ? <MuteIcon /> : <TalkingIcon />}
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={(
                    <Tooltip>
                      {caller.videoMuted ? 'Start Video' : 'Stop Video'}
                    </Tooltip>
                  )}
                >
                  <Button
                    className="mr-2 p-2 rounded-circle"
                    variant={caller.videoMuted ? 'primary' : 'outline-primary'}
                    size="sm"
                    onClick={this.toggleVideo}
                  >
                    {caller.videoMuted ? <CameraOffIcon /> : <CameraIcon />}
                  </Button>
                </OverlayTrigger>
                {browser.os !== 'iOS' && browser.os !== 'Android OS'
                  && (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={(
                      <Tooltip>
                        {caller.shareScreen ? 'End Sharescreen' : 'Start Sharescreen'}
                      </Tooltip>
                  )}
                  >
                    <Button
                      className="mr-2 p-2 rounded-circle"
                      variant={caller.shareScreen ? 'primary' : 'outline-primary'}
                      size="sm"
                      onClick={this.toggleShareScreen}
                    >
                      <ShareScreenIcon />
                    </Button>
                  </OverlayTrigger>
                  )}
                <OverlayTrigger
                  placement="bottom"
                  overlay={(
                    <Tooltip>
                      {chatOpen ? 'Close Chat' : 'Open Chat'}
                    </Tooltip>
                  )}
                >
                  <Button
                    className="mr-2 p-2 rounded-circle position-relative"
                    variant={unreadMessages > 0 ? 'success' : chatOpen ? 'primary' : 'outline-primary'}
                    size="sm"
                    onClick={() => { this.setState({ chatOpen: !chatOpen }); }}
                  >
                    <ChatIcon />
                    {unreadMessages > 0 && <Badge variant="danger" className="position-absolute" style={{ right: -5, top: -5 }}>{unreadMessages}</Badge>}
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={(
                    <Tooltip>
                      Send Files
                    </Tooltip>
                  )}
                >
                  <Button
                    className="mr-2 p-2 rounded-circle position-relative"
                    variant="outline-primary"
                    size="sm"
                    onClick={this.handleFileButton}
                  >
                    <FileIcon />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={(
                    <Tooltip>
                      {`Currently using ${speakerView ? 'speaker' : 'panel'} view. Toggle to switch to ${speakerView ? 'panel' : 'speaker'} view`}
                    </Tooltip>
                  )}
                >
                  <div>
                    <Switch
                      checked={speakerView}
                      onChange={this.handleChangeView}
                      handleDiameter={28}
                      offColor="#007bff"
                      onColor="#007bff"
                      offHandleColor="#ffffff"
                      onHandleColor="#ffffff"
                      height={32}
                      width={60}
                      borderRadius={16}
                      activeBoxShadow="0px 0px 1px 2px #fffc35"
                      checkedIcon={(
                        <div className="d-flex h-100 align-items-center justify-content-center">
                          <PanelViewIcon color="white" />
                        </div>
                      )}
                      uncheckedIcon={(
                        <div className="d-flex h-100 align-items-center justify-content-center">
                          <SpeakerViewIcon color="white" />
                        </div>
                      )}
                      uncheckedHandleIcon={(
                        <div className="d-flex h-100 align-items-center justify-content-center">
                          <PanelViewIcon color="var(--primary)" />
                        </div>
                      )}
                      checkedHandleIcon={(
                        <div className="d-flex h-100 align-items-center justify-content-center">
                          <SpeakerViewIcon color="var(--primary)" />
                        </div>
                      )}
                      className="react-switch"
                      id="small-radius-switch"
                    />
                  </div>
                </OverlayTrigger>
              </div>
              <h3 className="pt-2">
                {`Participants (${numOtherParticipants + 1})`}
              </h3>
              <div className="overflow-hidden flex-fill w-100">
                <div className="overflow-auto h-100">
                  <div className="d-flex flex-row align-items-center">
                    <div className="mr-auto">
                      {`${caller.name} (You)`}
                    </div>
                    {caller.audioMuted ? <MuteIcon color="red" fontSize="1.25em" /> : <TalkingIcon color="gray" fontSize="1.25em" />}
                    {caller.videoMuted ? <CameraOffIcon color="red" fontSize="1.25em" /> : <CameraIcon color="gray" fontSize="1.25em" />}
                  </div>
                  {currentOnlineMembers.map((peer) => (
                    <div key={peer.peerId} className="d-flex flex-row align-items-center pt-1" role="button" onClick={() => this.handleVideoPin(peer.peerId)}>
                      <div className="mr-auto">{peer.name}</div>
                      {pin === peer.peerId && <PinIcon color="blue" fontSize="1.25em" />}
                      {peer.audioMuted ? <MuteIcon color="red" fontSize="1.25em" /> : <TalkingIcon color="gray" fontSize="1.25em" />}
                      {peer.videoMuted ? <CameraOffIcon color="red" fontSize="1.25em" /> : <CameraIcon color="gray" fontSize="1.25em" />}
                    </div>
                  ))}
                </div>
              </div>
              <Button
                className="w-100"
                variant="danger"
                size="sm"
                onClick={this.handleExitCall}
                disabled={loading === 'leave'}
              >
                LEAVE CALL
              </Button>
            </Col>
            <Col className="h-100 overflow-auto d-flex flex-column justify-content-start align-items-center" ref={this.videoRef}>
              {focusedPeer.peerId && numOtherParticipants > 1 && chatOpen
                && (
                <VideosOverflow id="videos" direction="row">
                  {currentOnlineMembers.map((peer) => {
                    if (peer !== focusedPeer) {
                      return (
                        <Video videoWidth="200px" key={peer.peerId} peer={peer} />
                      );
                    }
                    return null;
                  })}
                </VideosOverflow>
                )}
              <div className="flex-grow-1 d-flex justify-content-center align-items-center flex-column w-100">
                {focusedPeer.peerId
                  && (
                  <Video
                    videoWidth={`${videosWidth}px`}
                    videoHeight={`${videosHeight - 20 - (focusedPeer.peerId && numOtherParticipants > 1 && chatOpen ? 150 : 0)}px`}
                    key={focusedPeer.peerId}
                    peer={focusedPeer}
                    onTalk={this.handleTalking(focusedPeer.peerId)}
                  />
                  )}
                {!focusedPeer.peerId
                  && (
                  <VideosWrap id="videos">
                    {numOtherParticipants === 0
                      && (
                      <h2 className="text-center">
                        No one else here! Invite others to the meeting with this link.
                        <br />
                        <b>
                          {`${APP_URL}/room/${roomId}`}
                        </b>
                      </h2>
                      )}
                    {currentOnlineMembers.map((peer) => (
                      <Video
                        videoWidth={videoWidth}
                        key={peer.peerId}
                        peer={peer}
                        onTalk={this.handleTalking(peer.peerId)}
                      />
                    ))}
                  </VideosWrap>
                  )}
              </div>
            </Col>
            <Col sm="auto" className="h-100 p-0">
              {focusedPeer.peerId && numOtherParticipants > 1 && !chatOpen
                && (
                <VideosOverflow id="videos" direction="column">
                  {currentOnlineMembers.map((peer) => {
                    if (peer !== focusedPeer) {
                      return (
                        <Video videoWidth="280px" key={peer.peerId} peer={peer} onTalk={this.handleTalking(peer.peerId)} />
                      );
                    }
                    return null;
                  })}
                </VideosOverflow>
                )}
              <Chat width="280px" show={chatOpen} caller={caller} ref={this.chatRef} close={() => { this.setState({ chatOpen: false }); }} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  render() {
    return window.screen.width < 600 || window.screen.height < 600
      ? this.renderMobile() : this.renderDesktop();
  }
}

Call.defaultProps = {
  caller: null,
  roomId: '',
  goHome: () => {},
};

Call.propTypes = {
  caller: PropTypes.instanceOf(Caller),
  roomId: PropTypes.string,
  goHome: PropTypes.func,
};
