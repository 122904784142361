import { detect } from 'detect-browser';
import hark from 'hark';

const browser = detect();

export const VIDEO_BITRATE = browser.os === 'iOS' || browser.os === 'Android OS' ? () => 1000 : (peers) => (peers ? Math.floor(2000 / peers) : 2000);
export const AUDIO_BITRATE = () => 50;
const DATA_BITRATE = browser.os === 'iOS' || browser.os === 'Android OS' ? () => 100 : () => 2000;

export const iceConfiguration = {
  iceServers: [
    {
      urls: [
        'stun:stun1.l.google.com:19302',
        'stun:stun2.l.google.com:19302',
      ],
    },
    {
      urls: 'turn:numb.viagenie.ca',
      credential: 'muazkh',
      username: 'webrtc@live.com',
    },
  ],
  iceCandidatePoolSize: 10,
};

export const callConfig = {
  video: {
    minWidth: {
      ideal: 3000,
    },
    facingMode: {
      ideal: 'user',
    },
  },
  audio: {
    echoCancellation: true,
    noiseSuppression: true,
    autoGainControl: true,
    googEchoCancellation: true,
    googAutoGainControl: true,
    googAutoGainControl2: true,
    googNoiseSuppression: true,
    googHighpassFilter: true,
    googTypingNoiseDetection: true,
  },
};

const AudioContext = window.AudioContext || window.webkitAudioContext;
const audioContext = new AudioContext();
audioContext.resume();

export const silence = () => Object.assign(
  audioContext.createMediaStreamDestination().stream.getAudioTracks()[0],
  { enabled: false },
);

export const black = () => {
  const canvas = Object.assign(document.createElement('canvas'), { width: 360, height: 270 });
  const ctx = canvas.getContext('2d');
  setInterval(() => {
    ctx.fillStyle = '#5F05FF';
    ctx.fillRect(0, 0, 100, 100);
    ctx.fillStyle = '#5105FF';
    ctx.fillRect(0, 0, 100, 100);
    ctx.fillStyle = '#5F058F';
    ctx.fillRect(0, 0, 100, 100);
  }, 1000);
  const stream = canvas.captureStream(0);
  const track = stream.getVideoTracks()[0];
  track.onended = () => {
    canvas.remove();
  };
  return Object.assign(track, { enabled: false });
};

export const blackSilence = () => new MediaStream([black(), silence()]);

export function setMediaBitrate(sdp, media, bitrate) {
  const lines = sdp.split('\n');
  let line = -1;
  for (let i = 0; i < lines.length; i += 1) {
    if (lines[i].indexOf(`m=${media}`) === 0) {
      line = i;
      break;
    }
  }
  if (line === -1) {
    console.debug('Could not find the m line for', media);
    return sdp;
  }
  console.debug('Found the m line for', media, 'at line', line);

  // Pass the m line
  line += 1;

  // Skip i and c lines
  while (lines[line].indexOf('i=') === 0 || lines[line].indexOf('c=') === 0) {
    line += 1;
  }

  // If we're on a b line, replace it
  if (lines[line].indexOf('b') === 0) {
    console.debug('Replaced b line at line', line);
    lines[line] = `b=AS:${bitrate}`;
    return lines.join('\n');
  }

  // Add a new b line
  console.debug('Adding new b line before line', line);
  let newLines = lines.slice(0, line);
  newLines.push(`b=AS:${bitrate}`);
  newLines = newLines.concat(lines.slice(line, lines.length));
  return newLines.join('\n');
}

export function setMediaBitrates(oldsdp) {
  let sdp = setMediaBitrate(oldsdp, 'video', VIDEO_BITRATE());
  sdp = setMediaBitrate(sdp, 'audio', AUDIO_BITRATE());
  return setMediaBitrate(sdp, 'application', DATA_BITRATE());
}

export function getMediaBitrate(sdp, media) {
  const lines = sdp.split('\n');
  let line = -1;
  for (let i = 0; i < lines.length; i += 1) {
    if (lines[i].indexOf(`m=${media}`) === 0) {
      line = i;
      break;
    }
  }
  if (line === -1) {
    console.debug('Could not find the m line for', media);
    return 10000; // infinity value?
  }
  console.debug('Found the m line for', media, 'at line', line);

  // Pass the m line
  line += 1;

  // Skip i and c lines
  while (lines[line].indexOf('i=') === 0 || lines[line].indexOf('c=') === 0) {
    line += 1;
  }

  // If we're on a b line, replace it
  if (lines[line].indexOf('b') === 0) {
    return parseInt(lines[line].substring(5), 10);
  }
  return 10000; // infinity value?
}

export function initHark(o) {
  audioContext.resume();
  const speechEvents = hark(o.stream, { audioContext });
  if (o.speechEvents) {
    console.log(o.speechEvents, o.peerId);
    speechEvents.on('speaking', o.speechEvents.callbacks.speaking[0]);
    speechEvents.on('stopped_speaking', o.speechEvents.callbacks.stopped_speaking[0]);

    o.speechEvents.stop();
    o.speechEvents.off('speaking');
    o.speechEvents.off('stopped_speaking');

    delete o.speechEvents;
  } else {
    speechEvents.on('speaking', () => { console.log('speaking'); });
    speechEvents.on('stopped_speaking', () => { console.log('stopped_speaking'); });
  }
  o.speechEvents = speechEvents;
}
